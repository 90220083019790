import { Pipe, PipeTransform } from '@angular/core'

// Pipes
import { NumberPipe } from './number.pipe'

/**
 * Transforms equipment miles and hours to the required format.
 *
 * @usageNotes
 *
 * The following example defines a view that allows the user to enter
 * an equipment object, meter or miles field and then uses the pipe
 * to convert the input with the correct format, miles formatting or
 * only show the correct meter.
 *
 * @example
 * !!(equipment | rasMeter)
 * {{ data | rasMeter }}
 *
 */
@Pipe({
    name: 'rasMeter',
    standalone: false
})
export class RasMeterPipe implements PipeTransform {
    constructor(private numberPipe: NumberPipe) {}

    transform(asset: any): any {
        const { meter_hours, meter_miles, display_meter_uom } = asset
        if (['hours', 'H'].includes(display_meter_uom) && meter_hours && meter_hours > 0) {
          return `${this.numberPipe.transform(asset.meter_hours)} hour${(asset.meter_hours > 1 ? 's' : '')}`
        }

        if (['miles', 'M', 'kilometers', 'K'].includes(display_meter_uom) && meter_miles) {
          let label = 'mile'
          if (['kilometers', 'K'].includes(display_meter_uom)) {
            label = 'kilometer'
          }
          return `${this.numberPipe.transform(asset.meter_miles)} ${label}${(asset.meter_miles > 1 ? 's' : '')}`
        }
    }
}
