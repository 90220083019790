import { ChangeDetectionStrategy, Component, ElementRef, HostListener, Input, OnDestroy, ViewChild, ViewEncapsulation } from '@angular/core'
import { Subject } from 'rxjs'

@Component({
    selector: 'tool-help',
    templateUrl: './help.html',
    styleUrls: ['./help.scss', '../control.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class HelpToolComponent implements OnDestroy {
    private $destroy = new Subject<void>()

    @Input() key: string
    @Input() content: string

    active: boolean = false

    @ViewChild('helpContainer') helpContainer: ElementRef
    @ViewChild('helpContent') helpContent: ElementRef

    constructor() {}

    @HostListener('document:click', ['$event'])
    clickout(event) {
        if (!this.helpContainer.nativeElement.contains(event.target)) {
            this.hidePopup()
        }
    }

    ngOnDestroy(): void {
        this.$destroy?.next()
        this.$destroy?.complete()
    }

    togglePopup(): void {
        if (this.helpContent.nativeElement.classList.contains('hide')) {
            this.showPopup()
        } else {
            this.hidePopup()
        }
    }

    showPopup(): void {
        this.helpContent.nativeElement.classList.remove('hide')
        this.active = true
    }

    hidePopup(): void {
        this.helpContent.nativeElement.classList.add('hide')
        this.active = false
    }
}
