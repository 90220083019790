import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core'
import { Subject } from 'rxjs'
import set from 'lodash-es/set'
import { ConfigurationService } from 'src/app/services/configuration.service'
import { CurrencyService } from 'src/app/services/currency.service'
import { take, takeUntil } from 'rxjs/operators'

interface ICurrencyOption {
    label?: string
    char?: string
    code?: string
    flag?: string
}

@Component({
    selector: 'tool-currency-options',
    templateUrl: './currency-options.html',
    styleUrls: ['./currency-options.scss', '../control.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class CurrencyOptionsToolComponent implements OnDestroy, OnInit {
    private $destroy = new Subject<void>()

    @Input() options: any

    catalogConfig: any
    currencyBase: string
    currencyList: Array<ICurrencyOption>
    currencies: Array<ICurrencyOption> = [
        {
            label: 'United States Dollar',
            char: '$',
            code: 'usd',
            flag: 'us',
        },
        {
            label: 'Canadian Dollar',
            char: '$',
            code: 'cad',
            flag: 'ca',
        },
        {
            label: 'Mexican Peso',
            char: '$',
            code: 'mxn',
            flag: 'mx',
        },
        {
            label: 'Euro',
            char: '€',
            code: 'eur',
            flag: 'eu',
        },
        {
            label: 'Japanese Yen',
            char: '¥',
            code: 'jpy',
            flag: 'jp',
        },
        {
            label: 'Australian Dollar',
            char: '$',
            code: 'aud',
            flag: 'au',
        },
        {
            label: 'Chinese Renminbi',
            char: '¥',
            code: 'cny',
            flag: 'ch',
        },
        {
            label: 'Korean Won',
            char: '₩',
            code: 'krw',
            flag: 'kr',
        }
    ]

    constructor(
        private configurationService: ConfigurationService,
        private currencyService: CurrencyService,
    ) {}

    ngOnInit(): void {
        this.catalogConfig = this.configurationService.getConfig('catalog')
        this.currencyBase = this.catalogConfig.currency_base || 'usd'
        this.currencyList = this.catalogConfig.currency_list || []
    }

    ngOnDestroy(): void {
        this.$destroy?.next()
        this.$destroy?.complete()
    }

    checkCurrencySelected(code: string): boolean {
        return !!this.currencyList.find(item => item.code === code)
    }

    checkCurrencyDisabled(code: string): boolean {
        return code === this.currencyService.USD_CURRENCY.code
    }

    processChecked(event: any, code: string): void {
        if (event.target.checked) {
            this.processAdd(code)
        } else {
            this.processRemove(code)
        }
    }

    processAdd(code: string): void {
        const item = this.currencies.find(currency => currency.code === code)
        this.currencyList.push(item)
        this.saveCurrencyList()
        if (this.currencyList.length === 1) {
            this.setCurrencyBase(this.currencyList[0].code)
        }
    }

    processRemove(code: string): void {
        const index = this.currencyList.findIndex(currency => currency.code === code)
        this.currencyList.splice(index, 1)
        this.saveCurrencyList()
        if (this.currencyList.length > 0 && code === this.currencyBase) {
            this.setCurrencyBase(this.currencyList[0].code)
        }
    }

    processDefaultCurrency(event: any): void {
        this.setCurrencyBase(event.target.value)
    }

    setCurrencyBase(code: string) {
        this.currencyBase = code
        set(this.configurationService.configuration, 'catalog.currency_base', this.currencyBase)
        this.configurationService.$configuration.next(this.configurationService.configuration)
        this.refreshCurrencyData()
    }

    saveCurrencyList(): void {
        set(this.configurationService.configuration, 'catalog.currency_list', this.currencyList)
        this.configurationService.$configuration.next(this.configurationService.configuration)
        this.refreshCurrencyData()
    }

    refreshCurrencyData(): void {
        this.currencyService.populateCurrencyDataFromConfig()
        this.currencyService.init(true)
            .pipe(
                takeUntil(this.$destroy),
                take(1),
            )
            .subscribe()
    }
}
