import { ChangeDetectionStrategy, ChangeDetectorRef, Component, DoCheck, ElementRef, HostBinding, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core'
import { Subject } from 'rxjs'

// Services
import { UtilityService } from 'src/app/services/utility.service'
import { ThemeService } from 'src/app/services/theme.service'

// Interfaces
import { IBaseComponent } from 'src/app/interfaces/components/component-base.interface'
import { IBadgeConfiguration } from 'src/app/interfaces/components/component-badge.interface'
import { IComponentDevOptions } from 'src/app/modules/dev/dev.page'

export const BadgeComponentDefaults: IBadgeConfiguration = {
    key: '',
    iconPack: 'fas',
    icon: '',
    label: '',
    styles: {},
}

export const BadgeComponentDevOpts: IComponentDevOptions = {
    config: {
        ...BadgeComponentDefaults,
        label: 'FEATURED',
        icon: 'star',
        styles: {}
    },
    controls: [{
        key: 'iconPack',
        type: 'select',
        options: [
            '',
            'fas',
            'far',
            'fab'
        ]
    }]
}

@Component({
    selector: 'ras-badge',
    templateUrl: './badge.component.html',
    styleUrls: ['./badge.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class BadgeComponent implements OnInit, OnDestroy, OnChanges, DoCheck, IBaseComponent {
    private $destroy = new Subject<void>()

    @Input() configuration: IBadgeConfiguration

    constructor(
        private ref: ChangeDetectorRef,
        private elRef: ElementRef,
        private themeService: ThemeService,
    ) { }

    ngOnInit(): void {
        UtilityService.populateConfigDefaults(this.configuration, BadgeComponentDefaults)
        this.themeService.setStylesOnElement(this.configuration.styles, this.elRef)
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (
            changes.configuration
            && changes.configuration.currentValue
            && changes.configuration.currentValue !== changes.configuration.previousValue
        ) {
            this.ref.markForCheck()
        }
    }

    ngDoCheck(): void {
        this.ref.markForCheck()
    }

    ngOnDestroy(): void {
        this.$destroy?.next()
        this.$destroy?.complete()
    }
}
