import { ChangeDetectionStrategy, Component, Input } from '@angular/core'


@Component({
    selector: '[ras-list-details-item]',
    styleUrls: ['./list-details-item.component.scss'],
    templateUrl: './list-details-item.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class ListDetailsItemComponent {
    @Input() data
}
