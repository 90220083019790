<button
    #button
    *ngIf="configuration.display && !!currencies && currencies.length > 0"
    (click)="onButtonClick()"
    class="currency-selector-button">
    <span
        *ngIf="configuration.showSymbolInButton"
        class="selected-currency-char">
        {{selectedCurrency.char}}
    </span>
    <span
        *ngIf="configuration.showCodeInButton"
        class="selected-currency-code">
        {{selectedCurrency.code}}
    </span>
    <span
        *ngIf="configuration.showArrowInButton"
        class="selected-currency-dropdown-arrow">
        <fa-icon
            [icon]="['fas','caret-down']">
        </fa-icon>
    </span>
</button>
<ul
    #list
    class="currency-selector-list hide">
    <li
        *ngFor="let opt of currencies"
        [class.selected]="opt.code === selectedCurrency.code"
        (click)="onOptionClick(opt.code)">
        <span
            *ngIf="opt.flag && configuration.showFlagInList"
            class="fi fi-{{opt.flag}}">
        </span>
        <span
            *ngIf="configuration.showSymbolInList"
            class="option-currency-char">
            {{opt.char}}
        </span>
        <span
            *ngIf="configuration.showCodeInList"
            class="option-currency-code">
            {{opt.code}}
        </span>
    </li>
</ul>