import {
    ChangeDetectionStrategy, ChangeDetectorRef, Component, HostListener, Input, OnDestroy, OnInit, ViewChild
} from '@angular/core'
import { combineLatest, Subject } from 'rxjs'

// Interfaces
import { CurrencyService } from 'src/app/services/currency.service'
import { MatSelect } from '@angular/material/select'
import { ICurrency } from 'src/app/interfaces/rouse-types/currency.interface'
import { filter, takeUntil } from 'rxjs/operators'

@Component({
    selector: 'ras-currency-selector',
    styleUrls: ['./currency-selector.component.scss'],
    templateUrl: './currency-selector.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class CurrencySelectorComponent implements OnInit, OnDestroy {

    private $destroy = new Subject<void>()
    selectedCurrency: string = 'usd'
    currencies: Array<ICurrency> = []

    @ViewChild('selectRef') selectRef: MatSelect

    constructor(private ref: ChangeDetectorRef,
                private currencyService: CurrencyService, ) {}

    ngOnInit(): void {
        combineLatest([
            this.currencyService.selectedCurrency,
            this.currencyService.availableCurrencies,
        ])
            .pipe(
                takeUntil(this.$destroy),
                filter(([currency, currencies]) => !!currency && !!currencies)
            )
            .subscribe(([currency, currencies]) => {
                this.currencies = currencies
                this.selectedCurrency = currency.code
                this.ref.markForCheck()
            })
    }

    ngOnDestroy(): void {
        this.$destroy.next()
        this.$destroy.complete()
    }

    onChange(event): void {
        this.currencyService.selectCurrencyByCode(event.value)
    }

    @HostListener('window:resize', [])
    onResize() {
        this.selectRef.close()
    }
}
