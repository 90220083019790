import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
    name: 'rasPhone',
    standalone: false
})
export class RasPhonePipe implements PipeTransform {

    transform(phone: any, locale?: any): any {
        if(!phone) {
            return null
        }

        switch( phone.length ) {
            case 11:
                const phoneWithCountryCode = phone.match(/^(.{1})(.{3})(.{3})(.{4})$/)
                return `+ ${phoneWithCountryCode[1]} (${phoneWithCountryCode[2]}) ${phoneWithCountryCode[3]}-${phoneWithCountryCode[4]}`
            case 10:
                const phoneRegular = phone.match(/^(.{3})(.{3})(.{4})$/)
                return `(${phoneRegular[1]}) ${phoneRegular[2]}-${phoneRegular[3]}`
            default:
                return phone
        }
    }

}
