@import "global.variables";

%container {
    margin: 0 15px;
    max-width: 100%;

    @media (min-width: $min-xs-width) {
        flex-direction: row;
        max-width: 100%;
    }

    @media (min-width: $min-sm-width) {
        max-width: 900px;
        margin: 0 auto;
    }

    @media (min-width: $min-md-width) {
        max-width: 1100px;
    }

    @media (min-width: $min-lg-width) {
        max-width: 1200px;
    }
}

%container-two-column-right {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    max-width: 100%;
    margin: 0 auto;
    padding: 0 15px;
    overflow: visible;

    @media (min-width: $min-xs-width) {
        flex-direction: row;
        max-width: 100%;
    }

    @media (min-width: $min-sm-width) {
        max-width: 900px;
    }

    @media (min-width: $min-md-width) {
        max-width: 1100px;
    }

    @media (min-width: $min-lg-width) {
        max-width: 1200px;
    }

    main {
        padding: 20px 0px;
        min-height: 100px;
        width: 100%;
        box-sizing: border-box;
        overscroll-behavior: contain;
        overflow: visible;

        @media (min-width: $min-xs-width) {
            flex-grow: 0;
            flex-shrink: 0;
            max-width: calc( 100% - 280px );
        }

        @media (min-width: $min-sm-width) {
            max-width: calc( 100% - 400px );
        }

        @media (min-width: $min-lg-width) {
            max-width: calc( 100% - 450px );
        }
    }

    aside {
        padding: 20px 0 30px 30px;
        box-sizing: border-box;
        border-color: transparent;

        @media (min-width: $min-xs-width) {
            flex-grow: 0;
            flex-shrink: 0;
            flex-basis: 280px;
        }

        @media (min-width: $min-sm-width) {
            flex-basis: 400px;
        }

        @media (min-width: $min-lg-width) {
            flex-basis: 450px;
        }
    }
}

%container-full-column-left {
    display: flex;
    flex-direction: column;

    @media (min-width: $min-xs-width) {
        flex-direction: row;
    }

    main {
        padding: 30px 0px;
        width: 100%;
        overflow: visible;
        box-sizing: border-box;

        @media (min-width: $min-xs-width) {
            width: calc( 100% - 360px ) ;
        }
    }

    aside {
        padding: 20px;
        width: 100%;
        box-sizing: border-box;
        border-color: transparent;
        background-color: $secondary-light-color;

        > div > div {
            padding: 10px;
        }

        @media (min-width: $min-xs-width) {
            // IE11 needs max and min :c
            min-width: 360px;
            max-width: 360px;
            background-color: transparent;
        }
    }
}
