import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms'
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, HostBinding, HostListener, Input, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core'
import { skip, takeUntil } from 'rxjs/operators'
import { Subject } from 'rxjs'

// Services
import { CurrencyService } from './../../../../services/currency.service'
import { ConfigurationService } from './../../../../services/configuration.service'
import { QueryService } from 'src/app/services/query.service'

// Interfaces
import { MatSnackBar } from '@angular/material/snack-bar'
import { MatDialog, MatDialogRef } from '@angular/material/dialog'
import { IChipsConfiguration } from 'src/app/interfaces/components/component-chips.interface'
import { IBaseComponent } from 'src/app/interfaces/components/component-base.interface'

@Component({
    selector: '#ras-chips',
    templateUrl: './chips.component.html',
    styleUrls: ['./chips.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class ChipsComponent implements IBaseComponent, OnInit, OnDestroy {

    constructor( private queryService: QueryService,
                 private configurationService: ConfigurationService,
                 private currencyService: CurrencyService,
                 private ref: ChangeDetectorRef,
                 private dialog: MatDialog,
                 private snackBar: MatSnackBar ) {}

    private $destroy = new Subject<void>()
    private dialogRef: MatDialogRef<any>

    @Input() configuration: IChipsConfiguration
    @ViewChild( 'menu' ) menu: TemplateRef<any>
    @ViewChild( 'saveButton' ) saveButton: ElementRef
    @ViewChild( 'filterNameInput' ) filterNameInput: ElementRef
    @HostBinding( 'class' ) cssClass: string

    public items: Array<any> = []
    public defaultItems: Array<any> = []
    public saveFilterForm = new UntypedFormGroup({
        filterName: new UntypedFormControl(
            '',
            [
                Validators.required,
                Validators.minLength( 2 ),
                Validators.maxLength( 128 )
            ]
        )
    })
    public storedParams = []

    @HostListener('window:resize') onResizeWindow() {
        this.resizeDialog()
    }

    ngOnInit() {

        this.currencyService.selectedCurrency
            .pipe(
                takeUntil( this.$destroy ),
                skip(1)
            )
            .subscribe( () => {
                window.location.reload()
            })

        this.queryService.getParams( this.configuration.dataGroup )
            .pipe( takeUntil( this.$destroy ) )
            .subscribe( params => {
                // Enables the display of default values, this ones are retrieved by each component defined on the configuration file
                if( this.configuration.showDefaults ) {
                    const defaultParams = this.queryService.getDefaultParamsGroup( this.configuration.dataGroup )

                    if( defaultParams ) {
                        this.defaultItems = this.filterParams( defaultParams ).filter( item => item )
                    }
                }

                this.items = []
                if( params ) {
                    this.items = this.filterParams( params ).filter( item => item ? typeof item.value === 'string' : false)
                }

                // Hide the host element if no items to be displayed
                this.cssClass = !this.items.length && !this.defaultItems.length ? 'hide' : ''

                this.ref.markForCheck()

            })
    }

    ngOnDestroy(): void {
        this.$destroy.next()
        this.$destroy.complete()
    }

    getLabel( key: string ) {
        return this.configurationService.configuration.filter.labels[key]
            ? this.configurationService.configuration.filter.labels[key]
            : key
    }


    getAriaLabel( label: string, prefix = '' ) {
        return `${ prefix } ${ label }`
    }

    onClearAll() {
        this.queryService.removeGroup( this.configuration.dataGroup )
    }

    onRemoveChip(dataGroup: string, key: string, value: string ) {
        this.queryService.remove( dataGroup, key, value )
    }

    onSaveFilters() {
        this.queryService.saveParams( this.saveFilterForm.controls.filterName.value, this.configuration.dataGroup )
        this.snackBar.open(
            'Filter saved!',
            '',
            {
                duration: 1000,
            })
        this.saveFilterForm.reset()
        this.dialogRef.close()
    }

    onCancelFilters() {
        this.saveFilterForm.reset()
        this.dialogRef.close()
    }

    onOpenDialog() {
        if( this.dialogRef && this.dialogRef.getState() === 0 ) {
            return
        }

        this.dialogRef = this.dialog.open( this.menu, {
            hasBackdrop: !this.getDialogState().isMobile ? false : true,
            panelClass: 'is-rounded'
        })
        this.resizeDialog()

        this.dialogRef.beforeClosed().pipe().subscribe()

    }

    private getDialogState() {
        const positionRight = Number( this.saveButton.nativeElement.offsetLeft ) + Number( this.saveButton.nativeElement.offsetWidth ) + 10
        const isMobile = ( window.innerWidth - positionRight ) < 120
        return { isMobile, positionRight }
    }

    private resizeDialog() {
        if( !this.dialogRef ) {
            return
        }

        const dialogState = this.getDialogState()

        if( !dialogState.isMobile ) {
            this.dialogRef.updatePosition({
                top: `${ this.saveButton.nativeElement.getBoundingClientRect().top }px`,
                left: `${ dialogState.positionRight }px`,
            })
        } else {
            this.dialogRef.updatePosition()
        }
    }

    private formatValuesOfArray(value, key, label, items) {
        let valueFormated

        switch( typeof value ) {
            default:
                valueFormated = value
                break
            case 'object':
                // In case the values is an array, we need to flatten the values, the label will become the value itself
                valueFormated = value.map(val => this.formatValuesOfArray(val, key, val, items))
                break
        }
        items.push({ value: valueFormated, key, label, group: this.configuration.dataGroup })
    }

    private filterParams( params: any ) {
        const items = []

        Object.keys( params )
            .filter( key => params[key] && !params[key].excluded )
            .map( key => {
                items.push(
                    this.formatValuesOfArray( params[key].value, key, params[key].label, items )
                )
            })

        return items
    }
}
