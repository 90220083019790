import { CurrencyPipe } from '@angular/common'
import { Pipe, PipeTransform } from '@angular/core'

import { DATA_CONFIG } from '../config/data.config'

@Pipe({
    name: 'money',
    standalone: false
})
export class MoneyPipe extends CurrencyPipe implements PipeTransform {

    transform(value: number, currency: string = '', symbol: string = ''): any {
        return !!value || value === 0 ? `${super.transform(value, currency, symbol, '1.0-0')} ${currency}` : DATA_CONFIG.nullString
    }
}
