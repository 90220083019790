import { DecimalPipe } from '@angular/common'
import { Pipe, PipeTransform } from '@angular/core'

import { DATA_CONFIG } from '../config/data.config'

@Pipe({
    name: 'number',
    standalone: false
})
export class NumberPipe extends DecimalPipe implements PipeTransform {

    transform(value: number): any {
        return !!value || value === 0 ? super.transform(value, '1.0-0') : DATA_CONFIG.nullString
    }
}
