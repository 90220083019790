import packageInfo from '../../package.json'

export const environment = {
    version: packageInfo.version,
    /**
     * Builds as production bundle if true
     */
    production: false,
    /**
     * Time to live for the services to be locally persisted on some services
     * (ContentfulService, ConfigurationService)
     */
    ttl: 180000,
    /**
     * For testing production with JS maps
     * (Automatically true if `production` option is true)
     * (Needed for testing in IE)
     */
    buildForProd: false,

    /**
     * Indicates what environment/stage bugs were reported on.
     */
    releaseStage: 'develop',

    /**
     * Authentication server for Rouse Auth0
     */
    auth: {
        domain: 'dev-login.rouseservices.com',
        clientId: 'xR8p7QaHiCelnSd9cZk1z1lA9rMitoz2',
        audience: 'https://api.rouseservices.com',
        useRefreshTokens: true,
        redirectUri: `${window.location.origin}/tools/components-config`,
    },
    /**
     * To target specific configuration on the mock server
     */
    client: null,

    /**
     * Rouse API servers
     */
    data: {
        pyApi: 'http://localhost:8888/', // Local
        apiLocal: null,
        api: '/api/',
        apiEnvironment: null,
        assetsUrl: 'https://catalog-assets-dev.rousesales.com/'
    },
    firebaseEnv: 'develop',
    firebaseConfig: {
        apiKey: "AIzaSyBwo3dy74VgNngJo29xcPXzFuEdQqbr3UM",
        authDomain: "rfm-dev-1e0721.firebaseapp.com",
        databaseURL: "https://rfm-dev-1e0721.firebaseio.com",
        projectId: "rfm-dev-1e0721",
        storageBucket: "rfm-dev-1e0721.appspot.com",
        messagingSenderId: "951920164207",
        appId: "1:951920164207:web:1643dbef2b892997a97c9a",
        measurementId: "G-VL4W2YF93L"
    },
    traceSampleRate: 0,
    /**
     * Request behavior settings
     */
    requests: {
        /**
         * Sets the number of requests to bundle into a single request
         * when request bundling is possible
         */
        batchSize: 6,
        /**
         * Turns on benchmarking for fetch service requests by default.
         * Note: Can turn on benchmarking manually by calling window.rasBenchOn()
         */
        defaultBenchmarkMode: false,
    },
    googleAnalytics: {
        trackingId: 'G-C0PE8WKTV3',
    },
    recaptchaSiteKey: '6LcElwUrAAAAAGwU33ky0O5fKCliT4G1AxwHLj7u',
    /**
     * Enables console-level debugging for specific uses
     */
    debugging: {
        // ----- Debugging Settings
        showTimeInLog: false,    // Shows the time in the console log
        showStackInLog: false,  // Appends the last stack trace to warnings and the calling line to for all other methods except 'error

        // ----- Components/Services
        general: false,         // Consoles out generic events that otherwise are silent
        authentication: false,  // Consoles out authentication events
        pageConstructor: false, // Consoles out page construction events
        fetch: false,           // Consoles out fetch errors and warnings
        filters: false,         // Consoles out filter errors and warnings
        landingRouter: false,   // Consoles out routing decisions
        qaStatus: false,        // Consoles out QA status events
        utility: false,         // Consoles out events occurring in the Utility Service
        session: false,         // Session consoling and timer
    }
}
