import { ChangeDetectionStrategy, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core'
import { Subject } from 'rxjs'
import { ConfigurationService } from 'src/app/services/configuration.service'
import set from 'lodash-es/set'
import unset from 'lodash-es/unset'
import get from 'lodash-es/get'
import { uniqueId } from 'lodash-es'

@Component({
    selector: 'tool-color-picker',
    templateUrl: './color-picker.html',
    styleUrls: ['./color-picker.scss', '../control.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class ColorPickerToolComponent implements OnDestroy, OnInit {
    private $destroy = new Subject<void>()

    @Input() configPath: string
    @Input() key: string
    @Input() label: string
    @Input() value: string
    @Input() options: any

    currentValue: string
    labelId: string
    enabled: boolean
    overrideEnabled: boolean = false

    @ViewChild('enabledButton') enabledButton: ElementRef
    @ViewChild('colorPicker') colorPicker: ElementRef
    @ViewChild('overrideInput') overrideInput: ElementRef

    get fullConfigPath(): string {
        return `${this.configPath}.${this.key}`
    }

    constructor(
        private configurationService: ConfigurationService,
    ) {
        this.labelId = uniqueId('color-picker')
    }

    ngOnInit(): void {
        if (!!get(this.configurationService.configuration, this.fullConfigPath)) {
            this.enabled = true
        }

        this.currentValue = this.value || ''
        if (!!this.currentValue) {
            this.overrideEnabled = true
            const colorStringTests = ['#', 'rgb(', 'rgba(']
            colorStringTests.forEach(str => {
                if (this.currentValue.indexOf(str) > -1) {
                    this.overrideEnabled = false
                }
            })
        }
    }

    ngOnDestroy(): void {
        this.$destroy?.next()
        this.$destroy?.complete()
    }

    processEnable(event: any): void {
        const enabled = event.target.checked
        this.enabled = enabled
        if (enabled && this.overrideEnabled) {
            this.setValue(this.overrideInput.nativeElement.value)
        } else if (enabled && !this.overrideEnabled) {
            this.setValue(this.colorPicker.nativeElement.value)
        } else {
            this.setValue(null)
        }
    }

    processChange(event: any): void {
        if (!this.enabled) {
            return
        }
        const value = event.target.value
        this.setValue(value)
    }

    processOverride(event: any): void {
        if (!this.enabled) {
            return
        }
        const value = event.target.value
        this.setValue(value)
    }

    setValue(value: string): void {
        if (!!value) {
            this.currentValue = value
            set(this.configurationService.configuration, this.fullConfigPath, value)
        } else {
            unset(this.configurationService.configuration, this.fullConfigPath)
        }
        this.configurationService.$configuration.next(this.configurationService.configuration)
    }

    toggleOverride(): void {
        this.overrideEnabled = !this.overrideEnabled
    }
}
