import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, HostBinding, HostListener, Input, OnDestroy, OnInit, SkipSelf, ViewChild } from '@angular/core'
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'
import { IBaseComponent } from 'src/app/interfaces/components/component-base.interface'
import { IComponentDevOptions } from 'src/app/modules/dev/dev.page'
import { ConfigurationService } from 'src/app/services/configuration.service'
import { ThemeService } from 'src/app/services/theme.service'
import { UtilityService } from 'src/app/services/utility.service'
import { IHeaderBasic1Configuration } from './header-basic-1.interface'

export const HeaderBasic1ComponentDefaults: IHeaderBasic1Configuration = {
    type: 'header_basic_1',
    display: true,
    breakpointMedium: 600,
    breakpointLarge: 800,
    includeHeaderTop: false,
    includeCurrencySelector: false,
    email: {
        href: '',
        content: ''
    },
    phone: {
        href: '',
        content: ''
    },
    logo: {
        src: '',
        alt: '',
        href: '',
    },
    styles: {},
    navItems: [],
    navSection: 'main',
    extraNavItems: [],
    childConfigs: {},
}

export const HeaderBasic1ComponentDevOpts: IComponentDevOptions = {
    config: {
        ...HeaderBasic1ComponentDefaults,
    },
}

@Component({
    selector: 'ras-header-basic-1',
    templateUrl: './header-basic-1.component.html',
    styleUrls: ['./header-basic-1.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class HeaderBasic1Component implements IBaseComponent, OnInit, OnDestroy, AfterViewInit {
    private $destroy = new Subject<void>()

    @ViewChild('headerTop') headerTopRef: ElementRef
    @ViewChild('headerMain') headerMainRef: ElementRef
    @ViewChild('headerMobile') headerMobileRef: ElementRef

    @Input() configuration: IHeaderBasic1Configuration

    mode: 'small' | 'medium' | 'large'

    constructor(
        private elRef: ElementRef,
        @SkipSelf() private ref: ChangeDetectorRef, // SkipSelf allows hostClass change to be detected on first load
        private themeService: ThemeService,
        private configurationService: ConfigurationService,
    ) {
        this.configurationService.$configuration.pipe(
            takeUntil(this.$destroy)
        ).subscribe(() => {
            this.themeService.clearStylesOnElement(elRef)
            this.themeService.setStylesOnElement(this.configuration.styles, this.elRef)
            this.setBreakpoints()
            this.ref.markForCheck()
        })
    }

    @HostBinding('class') hostClass: string

    @HostListener('window:resize', [])
    onResize() {
        this.setBreakpoints()
    }

    ngOnInit(): void {
        UtilityService.populateConfigDefaults(this.configuration, HeaderBasic1ComponentDefaults)
        this.themeService.setStylesOnElement(this.configuration.styles, this.elRef)
        this.ref.markForCheck()
    }

    ngAfterViewInit(): void {
        this.setCalculatedStyles()
        this.setBreakpoints()
        this.ref.detectChanges()
    }

    ngOnDestroy(): void {
        this.$destroy?.next()
        this.$destroy?.complete()
    }

    setCalculatedStyles(): void {
        let headerHeight = 0
        headerHeight += this.headerTopRef ? this.headerTopRef.nativeElement.offsetHeight : 0
        headerHeight += this.headerMainRef ? this.headerMainRef.nativeElement.offsetHeight : 0
        this.themeService.setStyleOnElement('calculated-header__height', headerHeight, this.elRef)
    }

    setBreakpoints(): void {
        const width = document.body.clientWidth
        if (width >= this.configuration.breakpointLarge) {
            if (this.hostClass !== 'breakpoint-large') {
                this.setMode('large')
            }
            return
        }
        if (width >= this.configuration.breakpointMedium) {
            if (this.hostClass !== 'breakpoint-medium') {
                this.setMode('medium')
            }
            return
        }
        if (this.hostClass !== 'breakpoint-small') {
            this.setMode('small')
        }
    }

    setMode(mode: 'small' | 'medium' | 'large'): void {
        this.mode = mode
        this.hostClass = `breakpoint-${mode}`
        this.ref.detectChanges()
        this.ref.markForCheck()
    }

    mouseEnterGroup(item: any): void {
        item.hover = true
        this.ref.detectChanges()
        this.ref.markForCheck()
    }

    mouseLeaveGroup(item: any): void {
        item.hover = false
        this.ref.detectChanges()
        this.ref.markForCheck()
    }

    checkHideGroupItem(item: any): boolean {
        const test = (this.mode === 'large' || this.mode === undefined)
            && !item?.hover
        return test
    }

    checkIncludeNavItemSection(section: string): boolean {
        if (
            this.configuration.navItems.length < 1
            || this.configuration.navSection !== section
        ) {
            return false
        }
        return true
    }

    checkIncludeExtraNavSection(): boolean {
        if (this.configuration.extraNavItems.length < 1) {
            return false
        }
        return true
    }
}
