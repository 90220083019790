import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core'
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'

// Services
import { QueryService } from 'src/app/services/query.service'

// Interfaces
import { ISaveFiltersConfiguration } from 'src/app/interfaces/filters/filters-save.interface'

@Component({
    selector: '#ras-save-filters-list',
    templateUrl: './save-filters-list.component.html',
    styleUrls: ['./save-filters-list.component.scss'],
    standalone: false
})
export class SaveFiltersListComponent implements OnInit {
    private $destroy = new Subject<void>()

    @Input() configuration: ISaveFiltersConfiguration

    public storedParams: Array<string> = []
    public state = false

    constructor(private queryService: QueryService, private ref: ChangeDetectorRef) { }

    ngOnInit() {
        this.state = !!this.configuration.isOpen
        this.queryService.getParamsStored()
            .pipe(
                takeUntil( this.$destroy )
                )
            .subscribe( storedParams => {
                if( storedParams ) {
                    this.storedParams = storedParams[ this.configuration.dataGroup ] ? Object.keys( storedParams[ this.configuration.dataGroup ] ) : []
                    this.ref.markForCheck()
                }
            })
    }

    onApplyFilter( key: string ) {
        const params = this.queryService.getParamStoredValue( key, this.configuration.dataGroup )
        if ( params ) {
            this.queryService.replaceFromParams( params )
        }
    }

}
