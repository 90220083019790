import { ChangeDetectionStrategy, OnChanges } from '@angular/core'
import { SimpleChanges } from '@angular/core'
import { Component, Input, ViewChild } from '@angular/core'

import { RenderService } from './render.service'

import { ZoneDirective } from './zone.directive'

/**
 * @description Generates the Catalog zones, each zone is injected by N number of components
 * The host component will contain a specific ID that matches the Key of the structure and zone, helpful to override styles
 */
@Component({
    selector: '[ras-zone]',
    template: `
        <!-- Content projected prior to dynamic components  -->
        <ng-content select="[content-before]"></ng-content>
        <!-- Dynamic components renders here -->
        <ng-template zoneHost></ng-template>
        <!-- Content projected after to dynamic components  -->
        <ng-content select="[content-after]"></ng-content>
    `,
    host: { '[id]': 'structureId + "_" + zoneId' },
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class ZoneComponent implements OnChanges {
    @Input() structureId: string
    @Input() zoneId: string

    @ViewChild( ZoneDirective, { static: true } ) zoneHost: ZoneDirective

    constructor( private render: RenderService ) { }

    ngOnChanges( changes: SimpleChanges ) {
        if( changes.structureId ) {
            this.zoneHost.viewContainerRef.clear()
            if (this.zoneId === 'header') {
                this.render.header(this.structureId, this.zoneHost)
            } else if (this.zoneId === 'footer') {
                this.render.footer(this.structureId, this.zoneHost)
            } else {
                this.render.zone( this.structureId, this.zoneId, this.zoneHost )
            }
        }
    }
}
