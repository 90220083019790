import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Input,
    OnDestroy,
    OnInit,
    ViewChild,
} from '@angular/core'
import { Subject } from 'rxjs'
import { UtilityService } from 'src/app/services/utility.service'
import { ThemeService } from 'src/app/services/theme.service'
import { ConfigurationService } from 'src/app/services/configuration.service'
import { IComponentDevOptions } from 'src/app/modules/dev/dev.page'
import { ModalService } from 'src/app/services/modal.service'
import { IModalLinkConfiguration } from 'src/app/interfaces/components/component-modal-link.interface'
import { takeUntil } from 'rxjs/operators'
import { ActivatedRoute } from '@angular/router'

export const ModalLinkComponentDefaults: IModalLinkConfiguration = {
    type: 'modal',
    display: true,
    targetModal: 'new-modal',
    labelIcon: null,
    label: 'Open Modal',
    content: [],
    styles: {}
}

export const ModalLinkComponentDevOpts: IComponentDevOptions = {
    config: {
        ...ModalLinkComponentDefaults,
        labelIcon: ['far', 'copy']
    }
}

@Component({
    selector: 'ras-modal-link',
    styleUrls: ['./modal-link.component.scss'],
    templateUrl: './modal-link.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class ModalLinkComponent implements OnInit, OnDestroy {

    private $destroy = new Subject<void>()

    @Input() configuration: IModalLinkConfiguration

    active: boolean = false
    params: any = {}

    constructor(
        private ref: ChangeDetectorRef,
        private elRef: ElementRef,
        private themeService: ThemeService,
        private configurationService: ConfigurationService,
        private modalService: ModalService,
        private route: ActivatedRoute,
    ) {
        this.configurationService.$configuration.pipe(
            takeUntil(this.$destroy)
        ).subscribe(() => {
            this.themeService.clearStylesOnElement(elRef)
            this.themeService.setStylesOnElement(this.configuration.styles, this.elRef)
            this.ref.markForCheck()
        })

        this.route.params.pipe(
            takeUntil(this.$destroy),
        ).subscribe(params => {
            this.params = params
        })
    }

    ngOnInit(): void {
        this.configuration = UtilityService.populateConfigDefaults(this.configuration, ModalLinkComponentDefaults)
        this.themeService.setStylesOnElement(this.configuration.styles, this.elRef)
    }

    ngOnDestroy(): void {
        this.$destroy.next()
        this.$destroy.complete()
    }

    openModal(): void {
        this.modalService.addModal({
            modalId: this.configuration.targetModal,
            content: this.configuration.content,
            styles: this.configuration.styles,
            params: this.params,
        })
    }
}
