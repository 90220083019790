import { Injectable } from '@angular/core'
import { LogService } from './log.service'
import { ConfigurationService } from '../services/configuration.service'
import { environment } from 'src/environments/environment'

declare global {
  interface Window { grecaptcha: any }
}

@Injectable({
  providedIn: 'root'
})
export class ReCaptchaService {

    constructor(
        private configurationService: ConfigurationService,
    ) { }

  init() {
    LogService.log('ReCaptchaService.init()', 'info')
    this.loadReCaptcha()
  }

  private getRecaptchaKey() {
    const recaptchaKey = this.configurationService.getConfig( 'catalog.recaptcha_site_key', environment.recaptchaSiteKey )
    if (!recaptchaKey) {
      LogService.log('ReCaptchaService.getRecaptchaKey() - No key found', 'warning')
    }
    return recaptchaKey
  }

  private loadReCaptcha() {
    LogService.log('ReCaptchaService.loadReCaptcha()', 'info')
    const recaptchaKey = this.getRecaptchaKey()
    if (!recaptchaKey) {
      return
    }
    const script = document.createElement('script')
    script.src = `https://www.google.com/recaptcha/enterprise.js?render=${recaptchaKey}`
    script.async = true
    document.body.appendChild(script)
    // this adds an object "grecaptcha" to the global window object
  }

  execute(action: string): Promise<string> {
    const response = new Promise<string>((resolve, reject) => {
      if (!window.grecaptcha) {
        LogService.log('ReCaptchaService.execute() - ReCaptcha not loaded', 'warning')
        reject('ReCaptcha not loaded.')
        return
      }
      const timeout = setTimeout(() => {
        reject(new Error('ReCaptcha ready timeout: window.grecaptcha.enterprise.ready did not resolve in time.'))
      }, 10000)
      window.grecaptcha.enterprise.ready(async () => {
        clearTimeout(timeout)
        try {
          const recaptchaKey = this.getRecaptchaKey()
          const token = await window.grecaptcha.enterprise.execute(recaptchaKey, { action }) as string
          resolve(token)
        } catch (error) {
          LogService.log('ReCaptchaService.execute() - Error executing ReCaptcha', error)
          reject('An error occurred while executing ReCaptcha.')
        }
      })
    })
    return response
  }
}