import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostBinding, Input, OnDestroy, OnInit } from '@angular/core'
import { takeUntil } from 'rxjs/operators'
import { Subject } from 'rxjs'

// Services
import { SearchService } from '../../../services/search.service'
import { UtilityService } from 'src/app/services/utility.service'

// Interfaces
import { IBaseComponent } from 'src/app/interfaces/components/component-base.interface'
import { IBadgesContainerConfiguration, IBadgeConfiguration } from 'src/app/interfaces/components/component-badge.interface'
import { IComponentDevOptions } from 'src/app/modules/dev/dev.page'

export const BadgesContainerComponentDefaults: IBadgesContainerConfiguration = {
    type: 'badges',
    display: true,
    limitTags: 2,
    cssClass: '',
    tags: [{
        key: 'is_featured',
        iconPack: 'fas',
        icon: 'star',
        label: 'FEATURED'
    }, {
        key: 'is_financing_eligible',
        iconPack: 'fas',
        icon: 'file-invoice-dollar',
        label: 'FINANCING AVAILABLE'
    }, {
        key: 'just_listed',
        iconPack: 'fas',
        icon: 'list-alt',
        label: 'JUST LISTED'
    }, {
        key: 'is_low_hours',
        iconPack: 'fas',
        icon: 'arrow-down',
        label: 'LOW HOURS'
    }, {
        key: 'is_warranty_eligible',
        iconPack: 'fas',
        icon: 'shield-alt',
        label: 'WARRANTY AVAILABLE'
    }, {
        key: 'more_tags',
        iconPack: '',
        icon: '',
        label: '+{{count}} MORE'
    }],
    orderTags: [
        'is_featured',
        'is_financing_eligible',
        'just_listed',
        'is_low_hours',
        'is_warranty_eligible',
        'more_tags'
    ],
    ignoreTags: [],
    allowTags: [],
    badgeStyles: {},
}

export const BadgesContainerComponentDevOpts: IComponentDevOptions = {
    data: {
        is_featured: true,
        is_financing_eligible: true,
        just_listed: true,
        is_low_hours: true,
        is_warranty_eligible: true
    },
    config: {
        ...BadgesContainerComponentDefaults,
    },
    controls: []
}

@Component({
    selector: 'ras-badges-container',
    templateUrl: './badges-container.component.html',
    styleUrls: ['./badges-container.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class BadgesContainerComponent implements OnInit, OnDestroy, IBaseComponent {
    private $destroy = new Subject<void>()

    public availableTags: Array<IBadgeConfiguration>
    public readonly MORE_TAGS_KEY = 'more_tags'

    @Input() data: any
    @Input() configuration: IBadgesContainerConfiguration

    @HostBinding('class') hostClass: string

    constructor(
        private searchService: SearchService,
        private ref: ChangeDetectorRef,) { }

    ngOnInit(): void {
        if (this.configuration === undefined) {
            this.configuration = {
                ...BadgesContainerComponentDefaults,
            }
        }
        UtilityService.populateConfigDefaults(this.configuration, BadgesContainerComponentDefaults)
        this.hostClass = this.configuration.cssClass

        if( this.configuration && this.configuration.dataGroup ) {
            this.searchService.requestData(
                this.configuration.dataGroup,
            ).pipe(
                takeUntil( this.$destroy ),
            ).subscribe((data) => {
                this.data = data
                this.availableTags = this.getAvailableTags()
                this.ref.markForCheck()
            })
        } else {
            this.availableTags = this.getAvailableTags()
            this.ref.markForCheck()
        }
    }

    ngOnDestroy(): void {
        this.$destroy?.next()
        this.$destroy?.complete()
    }

    getTag(featureId: string): IBadgeConfiguration {
        return this.configuration.tags.find(t => t.key === featureId)
    }

    getAvailableTags(): Array<IBadgeConfiguration> {
        if (this.configuration === undefined) {
            return
        }

        let { tags } = this.configuration
        const {
            ignoreTags,
            allowTags,
            orderTags,
        } = this.configuration
        if (ignoreTags?.length > 0) {
            tags = tags.filter(t => !ignoreTags.includes(t.key))
        } else if (allowTags?.length > 0) {
            tags = tags.filter(t => allowTags.includes(t.key))
        }
        tags = tags.map(tag => ({
            ...tag,
            styles: this.configuration.badgeStyles,
        }))
        return tags.sort((a: IBadgeConfiguration, b: IBadgeConfiguration) => {
            const foundA = orderTags.includes(a.key)
            const foundB = orderTags.includes(b.key)
            if (foundA && foundB) {
                return orderTags.indexOf(a.key) < orderTags.indexOf(b.key) ? -1 : 1
            }
            if (foundA) {
                return -1
            }
            if (foundB) {
                return 1
            }
            return 0
        })
    }

    getTags(equipment: any): Array<IBadgeConfiguration> {
        return this.availableTags.filter( (tag) => equipment[tag.key] )
    }

    getTagsLimited( equipment: any ): Array<IBadgeConfiguration> {
        let equipmentTags = this.getTags(equipment)
        const { limitTags } = this.configuration

        if (equipmentTags.length > limitTags) {
            equipmentTags = equipmentTags.slice(0, limitTags)
        }

        return equipmentTags
    }

    getAdditionalTagLabel( equipment: any ): string {
        const tags = this.getTags(equipment)
        const { limitTags } = this.configuration
        let count = 0

        if (tags.length > limitTags) {
            count = tags.length - limitTags
        }

        return UtilityService.replaceTemplate(
            this.configuration.tags.find(t => t.key === this.MORE_TAGS_KEY).label,
            { count }
        )
    }

}
