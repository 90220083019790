import { ChangeDetectionStrategy, Component, Input } from '@angular/core'

@Component({
    template: `<p class="is-rounded"> {{ message }} </p>`,
    styleUrls: ['./blank.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class BlankComponent {
    @Input() message: string
}
