@import "global.variables";
@import "helpers/_loading_screeners";
@import 'helpers/_layouts';

$header-height: 55px;
$sticky-summary-height: 90px;
$sticky-summary-desktop-height: 75px;

.list-details-container {
    overflow: visible;

    &.is-header-fixed {
        padding-top: $header-height;

        .list-details-header {
            transform: translateY( $sticky-summary-height );
            position: fixed;
            left: 0;
            right: 0;
            text-align: center;

            @media screen and ( min-width: $min-xs-width ) {
                transform: translateY( $sticky-summary-desktop-height );
            }
        }
    }

    .list-details-header {
        top: 0;
        transform: translateY( 0 );
        background-color: #FFF;
        z-index: 90;
        height: $header-height;
        transition: all ease-in-out .2s;
        overflow: visible;

        ul {
            padding: 0;
            list-style: none;
            position: relative;
            overflow-x: auto;
            overflow-y: hidden;
            box-shadow: inset 0px -1px 0px $secondary-color;
            background-color: #FFF;

            @extend %container;

            &.single-line {
                white-space: nowrap;
            }

            li {
                top: 1px;
                display: inline-block;
                padding: 10px 15px;
                position: relative;
                border-bottom: 3px solid transparent;
                cursor: pointer;

                &:first-child {
                    padding-left: 0;
                }

                &.active {
                    font-weight: bold;
                    color: $primary-color;
                    border-bottom-color: $primary-color;
                }
            }

            @media screen and (min-width: $min-xs-width) {
                padding: 0;

                li {
                    padding: 15px 20px;
                }
            }
        }
    }

    .list-details-content {
        > div {
            margin: 15px 0 0 0;
        }
    }

    .list-details-container {
        border-bottom: 1px solid $primary-light-color;
        padding: 30px 0;

        &:last-child {
            border-bottom: none;
        }
    }

    .ras-download-flyer {
        font-size: 1rem;
        font-weight: bold;
    }

    // Target the specific loading styles
    &.is-loading {

        .list-details-header ul li {
            @extend %loading-screener;
            min-height: 0px;
            max-width: 60px;
            margin-right: 20px;
            padding: 15px;
        }

        .list-details-container  {
            p {
                @extend %loading-screener;
                min-height: 30px;
            }
            div {
                @extend %loading-screener;
                min-height: 60px;
                padding-bottom: 30px;
            }
        }
    }
}

.download-link-container {
    padding-left: 20px;
    text-indent: -14px;

    a {
        text-decoration: none;
        font-size: 1rem;
    }
}
