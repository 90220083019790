import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core'
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'

// Pipes
import { CurrencyService } from 'src/app/services/currency.service'
import { UntypedFormControl } from '@angular/forms'
import { QueryService } from 'src/app/services/query.service'

// Interfaces
import { IMinMaxFilterConfiguration } from 'src/app/interfaces/components/component-mix-max.interface'

@Component({
    selector: 'ras-filter-min-max',
    styleUrls: ['./min-max.component.scss'],
    templateUrl: './min-max.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class FilterMinMaxComponent implements OnInit, OnDestroy {
    private $destroy = new Subject<void>()

    @Input() configuration: IMinMaxFilterConfiguration

    minControl = new UntypedFormControl('', [])
    maxControl = new UntypedFormControl('', [])
    currencyRate: number = 1
    currencyChar: string = '$'
    currencyCode: string = 'usd'
    errMessage: string = null

    // default configuration options that can be overriden by the config file
    opts = {
        showClearButton: <boolean> true, // show the clear button on each field?
        applyOnClear: <boolean> false, // when clear button is clicked or field value deleted by user, auto apply?
        absPosClearButton: <boolean> true, // this moves the clear button outside the element so there's no line under it
        showCurrencyChar: <boolean> true, // show the currency character at the start of the input?
        showCurrencyCode: <boolean> true, // show the currency code at the end of the input?
    }

    constructor( private ref: ChangeDetectorRef,
                 private queryService: QueryService,
                 private currencyService: CurrencyService, ) {}

    initBooleanOptions() {
        Object.keys(this.opts).forEach((key) => {
            if (typeof this.opts[key] === 'boolean' && key in this.configuration) {
                this.opts[key] = !!this.configuration[key]
            }
        })
    }

    ngOnInit(): void {
        // init configuration
        this.initBooleanOptions()

        // init controls
        this.minControl.reset()
        this.maxControl.reset()

        // init query service
        this.queryService.addParamToFetch(this.configuration.dataGroup, this.configuration.key)
        this.queryService.paramsData
            .pipe(takeUntil(this.$destroy))
            .subscribe(params => {
                if (params === null) {
                    return
                }
                const minCurrent = this.queryService.getParamValue(this.configuration.dataGroup, this.configuration.keyMin) as string
                const maxCurrent = this.queryService.getParamValue(this.configuration.dataGroup, this.configuration.keyMax) as string
                if (!!minCurrent) {
                    const min = Math.round(parseFloat(minCurrent) * this.currencyRate)
                    this.minControl.setValue(min)
                    this.ref.markForCheck()
                } else {
                    this.minControl.reset()
                }
                if (!!maxCurrent) {
                    const max = Math.round(parseFloat(maxCurrent) * this.currencyRate)
                    this.maxControl.setValue(max)
                    this.ref.markForCheck()
                } else {
                    this.maxControl.reset()
                }
            })

        // init currency service
        this.currencyService.selectedCurrency
            .pipe(takeUntil(this.$destroy))
            .subscribe(data => {
                if (data) {
                    this.currencyRate = data.rate
                    this.currencyChar = data.char
                    this.currencyCode = data.code
                }
            })
    }

    ngOnDestroy(): void {
        this.$destroy.next()
        this.$destroy.complete()
    }

    isValidValue(val: any) {
        return val !== 0 && !isNaN(val)
    }

    clearError() {
        this.errMessage = null
        this.ref.markForCheck()
    }

    showError(msg: string) {
        this.errMessage = msg
        this.ref.markForCheck()
    }

    onApply() {
        const min = parseInt(this.minControl.value, 10)
        const max = parseInt(this.maxControl.value, 10)
        if (
            this.isValidValue(min)
            && this.isValidValue(max)
            && max < min
        ) {
            this.showError('Max value must be greater than Min value.')
            return
        }

        this.clearError()
        if (!this.isValidValue(min)) {
            this.clearMin(true)
        } else {
            const calcualtedMin = min / this.currencyRate
            this.queryService.add(this.configuration.dataGroup, this.configuration.keyMin, calcualtedMin.toString())
        }
        if (!this.isValidValue(max)) {
            this.clearMax(true)
        } else {
            const calcualtedMax = max / this.currencyRate
            this.queryService.add(this.configuration.dataGroup, this.configuration.keyMax, calcualtedMax.toString())
        }
    }

    transform(event: any) {
        const control = event.target.name === 'min-input' ? this.minControl : this.maxControl
        let removeChar = null
        if (isNaN(event.data)) {
            removeChar = event.data
        }
        const inputVal = event.target.value?.replace(removeChar, '')
        if (isNaN(inputVal)) {
            control.setValue('')
        } else {
            control.setValue(inputVal)
        }
        this.ref.markForCheck()
    }

    clearMin(removeFromQueryService: boolean) {
        if (removeFromQueryService) {
            this.queryService.removeParamKey(this.configuration.dataGroup, this.configuration.keyMin)
        }
        this.minControl.reset()
    }

    clearMax(removeFromQueryService: boolean) {
        if (removeFromQueryService) {
            this.queryService.removeParamKey(this.configuration.dataGroup, this.configuration.keyMax)
        }
        this.maxControl.reset()
    }
}
