import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core'
import { Options } from '@angular-slider/ngx-slider'
import { takeUntil } from 'rxjs/operators'
import { Subject } from 'rxjs'

// Services
import { QueryService } from 'src/app/services/query.service'
import { LocationService } from 'src/app/services/location.service'

// Pipes
import { NumberPipe } from './../../../../pipes/number.pipe'

// Interfaces
import { IBaseComponent } from 'src/app/interfaces/components/component-base.interface'
import { IRangeLocationConfiguration } from 'src/app/interfaces/filters/filter-range-location.interface'

@Component({
    selector: '#ras-range-location',
    templateUrl: './range-location.component.html',
    styleUrls: ['./range-location.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class RangeLocationComponent implements OnInit, AfterViewInit, OnDestroy,  IBaseComponent {
    public $destroy = new Subject<void>()

    @Input() configuration: IRangeLocationConfiguration

    maxValue = 0
    useLocation = false

    options: Options = {
        floor: 0,
        ceil: 0,
        showSelectionBar: true,
        translate: ( value) => this.numberPipe.transform( value )  + (value === this.options.ceil ? ' <i>+</i>' : '')
    }

    constructor( private ref: ChangeDetectorRef,
                 private queryService: QueryService,
                 private numberPipe: NumberPipe,
                 private locationService: LocationService ) { }

    ngOnInit() {
        this.queryService.paramsData
            .pipe( takeUntil( this.$destroy ) )
            .subscribe( params => {
                if ( !!params ) {
                    this.handleParams()
                    this.ref.detectChanges()
                }
            })
        this.locationService.location$
            .pipe( takeUntil( this.$destroy ) )
            .subscribe( location => {
                if( location ) {
                    this.useLocation = true
                } else {
                    this.useLocation = false
                }
            })
    }

    ngAfterViewInit(): void {
        const options: Options = Object.assign( {}, this.options )

        options.ceil = this.configuration.maxRange,
        options.floor = this.configuration.minRange,
        options.step = this.configuration.incrementRange

        this.options = options
        this.ref.detectChanges()
    }

    ngOnDestroy() {
        this.$destroy.next()
        this.$destroy.complete()
    }

    onChange( event ) {
        if (event && event.value > this.options.floor  && event.value < this.options.ceil ) {
            this.queryService.add( this.configuration.dataGroup, this.configuration.key, event.value.toString() )
        } else {
            this.queryService.remove( this.configuration.dataGroup, this.configuration.key, event.value.toString() )
        }
    }

    /**
     * Applies the logic to handle the params for the location  values
     * @param params Object with all params
     */
    private handleParams() {
        const maxCurrent = this.queryService.getParamValue( this.configuration.dataGroup, this.configuration.key ) as string
        this.maxValue = maxCurrent ? ~~maxCurrent : this.options.floor
    }
}
