import {
    ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, HostListener, Input, OnDestroy, OnInit, ViewChild
} from '@angular/core'
import { combineLatest, Subject } from 'rxjs'
import { filter, takeUntil } from 'rxjs/operators'

// Services
import { CurrencyService } from 'src/app/services/currency.service'
import { UtilityService } from 'src/app/services/utility.service'
import { ThemeService } from 'src/app/services/theme.service'
import { ConfigurationService } from 'src/app/services/configuration.service'

// Interfaces
import { ICurrency } from 'src/app/interfaces/rouse-types/currency.interface'
import { ICurrencySelectorConfiguration } from 'src/app/interfaces/components/component-currency-selector.interface'
import { IComponentDevOptions } from 'src/app/modules/dev/dev.page'

export const CurrencySelector2ComponentDefaults: ICurrencySelectorConfiguration = {
    type: 'currency_selector',
    display: true,
    showArrowInButton: false,
    showSymbolInButton: false,
    showCodeInButton: false,
    showFlagInList: false,
    showSymbolInList: false,
    showCodeInList: false,
    styles: {}
}

export const CurrencySelector2ComponentDevOpts: IComponentDevOptions = {
    config: {
        ...CurrencySelector2ComponentDefaults,
        showArrowInButton: true,
        showSymbolInButton: true,
        showCodeInButton: true,
        showFlagInList: true,
        showSymbolInList: true,
        showCodeInList: true,
        styles: {
            'button-code__padding': '0 5px',
            button__border: '1px solid #000',
            'button__border-radius': '5px',
            button__padding: '5px 8px',
        }
    }
}

@Component({
    selector: 'ras-currency-selector-v2',
    styleUrls: ['./currency-selector.component.scss'],
    templateUrl: './currency-selector.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class CurrencySelectorV2Component implements OnInit, OnDestroy {

    private $destroy = new Subject<void>()
    selectedCurrency: ICurrency = {
        char: '',
        code: '',
    }
    currencies: Array<ICurrency> = []
    listDisplayed: boolean = false

    @ViewChild('button') buttonRef: ElementRef
    @ViewChild('list') listRef: ElementRef

    @Input() configuration: ICurrencySelectorConfiguration

    constructor(
        private ref: ChangeDetectorRef,
        private elRef: ElementRef,
        private currencyService: CurrencyService,
        private themeService: ThemeService,
        private configurationService: ConfigurationService,
    ) {
        this.configurationService.$configuration.pipe(
            takeUntil(this.$destroy)
        ).subscribe(() => {
            this.themeService.clearStylesOnElement(elRef)
            this.themeService.setStylesOnElement(this.configuration.styles, this.elRef)
            this.ref.markForCheck()
        })
    }

    @HostListener('window:resize', [])
    onResize() {
        this.hideList()
    }

    @HostListener('document:click', ['$event'])
    clickout(event) {
        if (!this.elRef.nativeElement.contains(event.target)) {
            this.hideList()
        }
    }

    ngOnInit(): void {
        this.configuration = UtilityService.populateConfigDefaults(this.configuration, CurrencySelector2ComponentDefaults)
        this.themeService.setStylesOnElement(this.configuration.styles, this.elRef)
        combineLatest([
            this.currencyService.selectedCurrency,
            this.currencyService.availableCurrencies,
        ]).pipe(
            takeUntil(this.$destroy),
            filter(([currency, currencies]) => !!currency && !!currencies)
        )
        .subscribe(([currency, currencies]) => {
            this.currencies = currencies
            this.selectedCurrency = currency
            this.ref.markForCheck()
        })
    }

    ngOnDestroy(): void {
        this.$destroy.next()
        this.$destroy.complete()
    }

    onButtonClick(): void {
        this.toggleList()
    }

    onOptionClick(currencyCode: string): void {
        this.currencyService.selectCurrencyByCode(currencyCode)
        this.hideList()
    }

    showList(): void {
        this.listRef.nativeElement.classList.remove('hide') // must show it before we position it
        this.positionList()
        this.listDisplayed = true
    }

    hideList(): void {
        this.listRef.nativeElement.classList.add('hide')
        this.listDisplayed = false
    }

    toggleList(): void {
        if (!this.listDisplayed) {
            this.showList()
        } else {
            this.hideList()
        }
    }

    positionList(): void {
        const styles: Array<string> = []
        this.addTopPosition(styles)
        this.addLeftPosition(styles)
        this.listRef.nativeElement.style = styles.join(';')
    }

    addTopPosition(styles: Array<string>): void {
        const btnTop = this.buttonRef.nativeElement.offsetTop
        const btnHeight = this.buttonRef.nativeElement.offsetHeight
        const top = btnTop + btnHeight
        // TODO convert to bottom up if it goes down past the body height
        // so we can have the selector in the footer
        styles.push(`top: ${top}px`)
    }

    addLeftPosition(styles: Array<string>): void {
        const btnLeft = this.buttonRef.nativeElement.offsetLeft
        const btnWidth = this.buttonRef.nativeElement.offsetWidth
        const listWidth = this.listRef.nativeElement.offsetWidth
        const bodyWidth = document.body.clientWidth
        let left = btnLeft
        const rightSide = left + listWidth
        if (rightSide >= bodyWidth) {
            // list extends beyond the view of the window so realign it to the right side of the button instead
            left = btnLeft + btnWidth - listWidth
        }
        styles.push(`left: ${left}px`)
    }
}
