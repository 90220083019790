import { ResolvedStore } from './../../services/resolved.store'
import { debounceTime } from 'rxjs/operators'
import { Subject } from 'rxjs'
import { ConfigurationService } from 'src/app/services/configuration.service'
import { Directive, Input, OnInit, ElementRef, HostBinding, HostListener, OnDestroy, ChangeDetectorRef } from '@angular/core'

export const DEFAULT_NO_IMAGE = 'none'

@Directive({
    selector: '[ras-image]',
    standalone: false
})
export class ImageDirective implements OnInit, OnDestroy {
    private readonly defaultSize = 'ItemDetailBig'
    private readonly sizes = [
        {
            name: 'Thumbnail',
            value: 60
        },
        {
            name: 'HertzThumbnail',
            value: 100
        },
        {
            name: 'FeaturedThumb',
            value: 112
        },
        {
            name: 'FeaturedItem',
            value: 160
        },
        {
            name: 'ItemDetailBig',
            value: 400
        },
        {
            name: 'ItemDetailLarge',
            value: 560
        },
        {
            name: 'ItemDetailExtended',
            value: 720
        },
        {
            name: 'ItemDetailExpanded',
            value: 1200
        }
    ]
    private $destroy = new Subject<void>()
    private triggerResize = new Subject<void>()
    private baseUrl: string

    @Input() id: string
    @Input() type: string
    @Input() resize: boolean
    @Input() transparent: boolean

    @HostBinding( 'src' ) src: string
    @HostListener( 'window:resize', [ '$event' ] ) onResize( event ) {
        this.triggerResize.next( event )
    }

    constructor( private configuration: ConfigurationService,
                 private resolvedStore: ResolvedStore,
                 private el: ElementRef,
                 private ref: ChangeDetectorRef) { }

    ngOnInit() {
        this.baseUrl = this.configuration.getConfig( 'catalog.image_base_url', null )

        this.setImage()

        // Only when resize is enabled the app can render different sizes of images available dynamically
        if (this.resize) {
            this.triggerResize
                .pipe( debounceTime( 200 ) )
                .subscribe( () => this.setImage() )
        }
    }

    ngOnDestroy() {
        this.$destroy.next()
        this.$destroy.complete()
    }

    private setImage() {
        let type = this.defaultSize

        // When resize is enable, the best suitable size is choosen
        if ( this.resize ) {
            type = this.getImageSizeType()
        } else {
            // When type is set, use the type otherwise the default one will be used
            type = this.type ? this.type : type
        }

        this.src = this.baseUrl ? `${ this.baseUrl }?${this.transparent ? '&transparent=true' : ''}&type=${ type }${ (this.id && this.id !== DEFAULT_NO_IMAGE ) ? `&guid=${this.id}`: ''}` : null
        this.ref.markForCheck()
    }

    private getImageSizeType() {
        const elementWidth = this.el.nativeElement.width
        const sizeTypeResolved = this.resolvedStore.imageSizes[ elementWidth ]

        if ( !sizeTypeResolved ) {
            let closestSize = { name: this.defaultSize , value: 9999 }
            this.sizes.forEach( size => {
                if( size.value >= elementWidth && size.value <= closestSize.value ) {
                    closestSize = size
                }
            })
            this.resolvedStore.imageSizes = { ... this.resolvedStore.imageSizes, [ elementWidth ]: closestSize.name }
            return closestSize.name
        }

        return sizeTypeResolved

    }

}
