import { NavigationExtras, Router } from '@angular/router'
import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    HostBinding,
    Input,
    OnDestroy,
    OnInit,
    ViewChild,
} from '@angular/core'
import { UntypedFormControl } from '@angular/forms'
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete'
import { MatBottomSheet } from '@angular/material/bottom-sheet'
import { BehaviorSubject, fromEvent, of, Subject } from 'rxjs'
import {
    debounceTime,
    distinctUntilChanged,
    filter,
    map,
    switchMap,
    take,
    takeUntil,
    tap,
} from 'rxjs/operators'
import get from 'lodash-es/get'
import startCase from 'lodash-es/startCase'

// Services
import { SearchService } from 'src/app/services/search.service'
import { UtilityService } from '../../../../services/utility.service'

// Interfaces
import { IBaseComponent } from 'src/app/interfaces/components/component-base.interface'
import { ISearchAdvancedConfiguration } from 'src/app/interfaces/components/component-search-advanced.interface'
import {
    ISearchItem,
} from 'src/app/interfaces/components/component-search.interface'
import { IFilterFieldIndex, IFilterSearchOption } from '../../../../interfaces'

// ...
import { SearchAdvancedMobileComponent } from './search.advanced.mobile.component'

@Component({
    selector: 'ras-filter-search-advanced',
    templateUrl: './search.advanced.component.html',
    styleUrls: ['./search.advanced.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class SearchAdvancedComponent implements OnInit, OnDestroy, AfterViewInit, IBaseComponent {
    private $destroy = new Subject<void>()
    private requestData = new BehaviorSubject<string>('')

    @ViewChild('zipCodeInput') input

    @Input() configuration: ISearchAdvancedConfiguration

    @HostBinding('style') get styles() {
        return this.configuration.styles
    }

    loading: boolean = false
    formControl = new UntypedFormControl()
    options: Array<ISearchItem>
    searchText: string = ''
    inlineStyles: string
    limit = 20
    debounceTime = 3000
    categories: Array<any> = []
    distances: Array<any> = []
    distance: number
    zipcode: string
    divText: string = null

    constructor(
        private searchService: SearchService,
        private ref: ChangeDetectorRef,
        private route: Router,
        private bottomSheet: MatBottomSheet
    ) { }

    ngAfterViewInit(): void {
        fromEvent(this.input.nativeElement, 'input')
          .pipe(
            map((event: Event) => (event.target as HTMLInputElement).value),
            debounceTime(this.debounceTime),
            distinctUntilChanged())
          .subscribe(data => this.onZipCodeChange(data))
      }

    ngOnInit(): void {
        this.limit = this.configuration.limit
        this.debounceTime = this.configuration.inputDebounceTime
        this.distances = this.configuration.distances
        this.divText = this.configuration.divText
        this.searchService.requestData(this.configuration.categoryDataGroup)
            .pipe(
                takeUntil( this.$destroy ),
                filter( data => !!data )
            )
            .subscribe( result =>  {
                this.categories = result.data.map( item => ({ name: item[this.configuration.categoryKey] }))
                this.ref.markForCheck()
            })
        this.formControl.valueChanges
            .pipe(
                takeUntil(this.$destroy),
                distinctUntilChanged(),
                debounceTime(500),
                tap(() => {
                    this.loading = true
                    this.ref.markForCheck()
                }),
                switchMap((value) => {
                    this.searchText = value
                    if (
                        !value ||
                        UtilityService.isNullBlankOrUndefined(value) ||
                        value.length < 2
                    ) {
                        return of([])
                    }
                    return this.searchService.search(value, this.limit)
                })
            )
            .subscribe((data: any) => {
                this.setResults(data)
            })
    }

    ngOnDestroy(): void {
        this.$destroy.next()
        this.$destroy.complete()

        this.requestData.next('')
        this.requestData.complete()
    }

    openAdvanceSearch(): void {
        const ref = this.bottomSheet.open(SearchAdvancedMobileComponent, {
            data: {
                categories: [...this.categories],
                locations: [...this.distances],
            },
            panelClass: 'is-rounded-top-lg',
        })
        ref.afterDismissed().pipe(take(1)).subscribe((option) => {
            if (option) {
                this.navigate(option)
            }
        })
    }

    onSelect(event: MatAutocompleteSelectedEvent): void {
        const el = get(event, 'option')
        const selection: ISearchItem = event.option.value
        let url = this.configuration.url
        let urlOptions: NavigationExtras = {
            queryParams: {
                [`${this.configuration.dataGroup}.${selection.key}`]: selection.value_id ?? selection.value,
            },
            queryParamsHandling: 'merge',
        }

        if (
            this.configuration.urlCustom &&
            this.configuration.urlCustom[selection.key]
        ) {
            url = UtilityService.replaceTemplate(
                this.configuration.urlCustom[selection.key],
                selection
            )
            urlOptions = {}
        }

        this.route.navigate([url], urlOptions)

        if (!get(selection, 'column')) {
            this.clearControl()
            return
        }

        if (!!el) {
            el.focus()
        }

        // clear out the search inputs
        this.clearControl()
    }

    onCategoryChange(category: string): void {
        const filterKey = this.configuration.categoryFilterKey ?? 'filter_category'
        this.navigate([{name: filterKey, value: category}])
    }

    onLocationChange(distance: number): void {
        if (this.zipcode) {
            this.navigate([{name:`distance`, value:this.distance},{name:`postal_code`, value:this.zipcode}])
        }
    }

    onZipCodeChange(zipcode: string): void {
        if(this.distance) {
            this.navigate([{name:`distance`, value:this.distance},{name:`postal_code`, value:this.zipcode}])
        }
    }

    clearControl(): void {
        this.zipcode = undefined
        this.distance = undefined
        this.formControl.setValue(undefined)
    }

    getGroupLabel(key: string) {
        return this.configuration.labels[key]
            ? this.configuration.labels[key]
            : key
    }

    getFilterLabel(key: string) {
        return key.replace('filter_', '')
    }

    setResults(results: Array<ISearchItem>): void {
        const resultsKeys = Object.keys(results)
        this.options = resultsKeys
            .map((key) => results[key].map((item) => ({
                    ...item,
                    key,
                    label: this.getLabel(item.value),
                })))
            .filter((group) => group.length)
        this.loading = false

        this.ref.markForCheck()
    }

    getLabel(value: string) {
        const searchRegEx: RegExp = new RegExp(`${this.searchText}`, 'ig')
        return value.replace(
            searchRegEx,
            `<strong>${this.searchText}</strong>`
        )
    }

    fixCase(val: string): string {
        return startCase(val)
    }

    displayValue(selection: IFilterFieldIndex): string | undefined {
        return !!selection
            ? !!selection.value
                ? selection.value.toString()
                : 'null'
            : undefined
    }

    tracker(index: number, item: IFilterSearchOption): string {
        return item.column + ':' + item.value
    }

    private navigate(options: Array<{name: string, value: string | number}>) {
        const urlOptions: NavigationExtras = {
            queryParams: {}
        }
        urlOptions.queryParams = options.reduce( (obj, item) => ({ ...obj, [`${this.configuration.dataGroup}.${item.name}`]: item.value  }) , {})

        const url = this.configuration.url

        this.route.navigate([url], urlOptions)

        // clear out the search inputs
        this.clearControl()
    }
}
