import { ChangeDetectionStrategy, Component, Input, OnDestroy, ViewEncapsulation } from '@angular/core'
import { Subject } from 'rxjs'

@Component({
    selector: 'tool-comment',
    templateUrl: './comment.html',
    styleUrls: ['./comment.scss', '../control.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class CommentToolComponent implements OnDestroy {
    private $destroy = new Subject<void>()

    @Input() content: string
    @Input() options: any

    constructor() {}

    ngOnDestroy(): void {
        this.$destroy?.next()
        this.$destroy?.complete()
    }
}
