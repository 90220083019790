import { Component, Input, Output, TemplateRef, ViewChild, EventEmitter, ChangeDetectionStrategy, AfterViewInit, ChangeDetectorRef } from '@angular/core'

@Component({
    selector: '[ras-render-element]',
    templateUrl: './render-element.component.html',
    styleUrls: ['./render-element.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class RenderElementComponent implements AfterViewInit {

    @Input() content: any
    @Output() open = new EventEmitter()
    @Output() jump = new EventEmitter()

    @ViewChild('divTemplate') divTemplate: TemplateRef<any>
    @ViewChild('anchorTemplate') anchorTemplate: TemplateRef<any>
    @ViewChild('listTemplate') listTemplate: TemplateRef<any>
    @ViewChild('listItemTemplate') listItemTemplate: TemplateRef<any>
    @ViewChild('imageTemplate') imageTemplate: TemplateRef<any>
    @ViewChild('spanTemplate') spanTemplate: TemplateRef<any>
    @ViewChild('iconTemplate') iconTemplate: TemplateRef<any>
    @ViewChild('anchorNgTemplate') anchorNgTemplate: TemplateRef<any>
    @ViewChild('buttonTemplate') buttonTemplate: TemplateRef<any>
    @ViewChild('paragraphTemplate') paragraphTemplate: TemplateRef<any>
    @ViewChild('rawTemplate') rawTemplate: TemplateRef<any>
    @ViewChild('pageJumpTemplate') pageJumpTemplate: TemplateRef<any>
    @ViewChild('currencySelectorTemplate') currencySelectorTemplate: TemplateRef<any>
    @ViewChild('modalLinkTemplate') modalLinkTemplate: TemplateRef<any>

    constructor( private ref: ChangeDetectorRef ) { }

    onOpen( data: any, element: Element ) {
        if(data.open || data.open_mobile_menu) {
            this.open.emit( { data, element } )
        }
    }

    onPageJump(link: string): void {
        this.jump.emit(link)
    }

    ngAfterViewInit() {
        this.ref.detectChanges()
    }

    getTemplateRef( element: string ) {
        switch ( element ) {
            case 'div':
                return this.divTemplate
            case 'a':
                return this.anchorTemplate
            case 'a-ng':
                return this.anchorNgTemplate
            case 'ul':
                return this.listTemplate
            case 'li':
                return this.listItemTemplate
            case 'img':
                return this.imageTemplate
            case 'span':
                return this.spanTemplate
            case 'icon':
                return this.iconTemplate
            case 'button':
                return this.buttonTemplate
            case 'p':
                return this.paragraphTemplate
            case 'raw':
                return this.rawTemplate
            case 'page-jump':
                return this.pageJumpTemplate
            case 'currency-selector':
                return this.currencySelectorTemplate
            case 'modal-link':
                return this.modalLinkTemplate
        }
    }

}
