import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core'
import { BehaviorSubject, Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'

// Services
import { QueryService } from 'src/app/services/query.service'
import { ConfigurationService } from 'src/app/services/configuration.service'

// Interfaces
import { IRangeSliderFilterConfiguration } from 'src/app/interfaces/components/component-range-price.interface'

@Component({
    selector: '#ras-filter-range-price',
    styleUrls: ['./range-price.component.scss'],
    templateUrl: 'range-price.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class RangePriceFilterComponent implements OnInit, OnDestroy {
    private $destroy = new Subject<void>()

    @Input() configuration: IRangeSliderFilterConfiguration

    public CURRENCY_SYMBOL = '$'

    public minValue: number = 0
    public maxValue: number = 150000
    public minValueSelected: number = null
    public maxValueSelected: number = null

    public isLoading: BehaviorSubject<boolean>

    constructor( private ref: ChangeDetectorRef,
                 private queryService: QueryService,
                 private configurationService: ConfigurationService ) {}

    ngOnInit(): void {
        this.CURRENCY_SYMBOL = this.configurationService.getConfig( 'catalog.currency_char', '$')
        this.isLoading = this.queryService.isLoading
        this.maxValue = this.configuration.pricingMaxValue
        this.minValue = this.configuration.pricingMinValue

        this.queryService.addParamToFetch( this.configuration.dataGroup, this.configuration.key )
        this.queryService.paramsData
            .pipe( takeUntil( this.$destroy ) )
            .subscribe( params => {
                if ( !!params && !!params[ this.configuration.key ] ) {
                    this.handleParams()
                    this.ref.markForCheck()
                }
            })
    }

    ngOnDestroy(): void {
        this.$destroy.next()
        this.$destroy.complete()
    }

    onChange( event: { max: number, min: number } ) {
        this.minValueSelected = event.min
        this.maxValueSelected = event.max

        if( event.max === this.configuration.pricingMaxValue ) {
            this.queryService.removeParamKey( this.configuration.dataGroup, this.configuration.keyMax )
        } else {
            this.queryService.add( this.configuration.dataGroup, this.configuration.keyMax, event.max.toString() )
        }

        if( event.min === this.configuration.pricingMinValue ) {
            this.queryService.removeParamKey( this.configuration.dataGroup, this.configuration.keyMin )
        } else {
            this.queryService.add( this.configuration.dataGroup, this.configuration.keyMin, event.min.toString() )
        }
    }

    /**
     * Applies the logic to handle the params for the pricing values
     * @param params Object with all params
     */
    private handleParams() {
        const minCurrent = this.queryService.getParamValue( this.configuration.dataGroup, this.configuration.keyMin ) as string
        const maxCurrent = this.queryService.getParamValue( this.configuration.dataGroup, this.configuration.keyMax ) as string

        this.minValueSelected = !!minCurrent ? ~~minCurrent : this.minValue
        this.maxValueSelected = !!maxCurrent ? ~~maxCurrent  : this.maxValue
    }
}
