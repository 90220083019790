import { ChangeDetectionStrategy, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core'
import { Subject } from 'rxjs'
import { ConfigurationService } from 'src/app/services/configuration.service'
import get from 'lodash-es/get'
import set from 'lodash-es/set'
import unset from 'lodash-es/unset'

@Component({
    selector: 'tool-text-input',
    templateUrl: './text-input.html',
    styleUrls: ['./text-input.scss', '../control.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class TextInputToolComponent implements OnDestroy, OnInit {
    private $destroy = new Subject<void>()

    @Input() configPath: string
    @Input() key: string
    @Input() label: string
    @Input() value: string
    @Input() options: any

    enabled: boolean
    currentValue: string

    @ViewChild('textInput') textInput: ElementRef

    get fullConfigPath(): string {
        return `${this.configPath}.${this.key}`
    }

    constructor(
        private configurationService: ConfigurationService,
    ) {}

    ngOnInit(): void {
        if (!!get(this.configurationService.configuration, this.fullConfigPath)) {
            this.enabled = true
        }
        this.currentValue = this.value || ''
    }

    ngOnDestroy(): void {
        this.$destroy?.next()
        this.$destroy?.complete()
    }

    processEnable(event: any): void {
        const enabled = event.target.checked
        this.enabled = enabled
        if (enabled) {
            this.setValue(this.textInput.nativeElement.value)
        } else {
            this.setValue(null)
        }
    }

    processChange(event: any): void {
        if (!this.enabled) {
            return
        }
        const value = event.target.value
        this.setValue(value)
    }

    processOverride(event: any): void {
        if (!this.enabled) {
            return
        }
        const value = event.target.value
        this.setValue(value)
    }

    setValue(value: string): void {
        if (!!value) {
            set(this.configurationService.configuration, this.fullConfigPath, value)
        } else {
            unset(this.configurationService.configuration, this.fullConfigPath)
        }
        this.configurationService.$configuration.next(this.configurationService.configuration)
    }
}
