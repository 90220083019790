import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core'
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'

// Services
import { SearchService } from './../../../services/search.service'
import { UtilityService } from 'src/app/services/utility.service'

// Interfaces
import { IBaseComponent } from 'src/app/interfaces/components/component-base.interface'
import { IOneCallConfiguration } from 'src/app/interfaces/components/component-one-call.interface'
import { IComponentDevOptions } from 'src/app/modules/dev/dev.page'

export const OneCallComponentDefaults: IOneCallConfiguration = {
    type: 'one_call',
    display: true,
    key: '',
    number: '',
    label: '',
    icon: 'phone',
}

export const OneCallComponentDevOpts: IComponentDevOptions = {
    config: {
        ...OneCallComponentDefaults,
        number: '+13103609200',
        label: '1 (310) 360-9200'
    },
}

@Component({
    selector: '.ras-one-call',
    templateUrl: './one-call.component.html',
    styleUrls: ['./one-call.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class OneCallComponent implements OnInit, OnDestroy, IBaseComponent {
    private $destroy = new Subject<void>()

    @Input() configuration: IOneCallConfiguration
    @Input() data: any

    constructor(private searchService: SearchService, private ref: ChangeDetectorRef) { }

    ngOnInit() {
        UtilityService.populateConfigDefaults(this.configuration, OneCallComponentDefaults)
        if (this.configuration.dataGroup) {
            this.searchService.requestData(this.configuration.dataGroup)
                .pipe(takeUntil(this.$destroy))
                .subscribe(data => {
                    this.data = { ...data }
                    this.ref.markForCheck()
                })
        }
    }

    ngOnDestroy() {
        this.$destroy.next()
        this.$destroy.complete()
    }
}
