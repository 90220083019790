import { HttpClient } from '@angular/common/http'
import { ChangeDetectionStrategy, Component, ViewChild } from '@angular/core'
import { IFormConfiguration } from 'src/app/interfaces/components/component-form.interface'
import { ConfigurationService } from 'src/app/services/configuration.service'
import { ConfigControlsComponent, ControlTypes, IComponentControl } from '../../controls/config-controls.component'
import { ConfigDirective } from '../config.directive'

@Component({
    selector: 'config-form',
    templateUrl: './config.form.html',
    styleUrls: ['./config.form.scss', '../config.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class FormConfigComponent extends ConfigDirective {
    basePath: string = 'components'

    defaultConfiguration: IFormConfiguration

    controls: Array<IComponentControl> = []
    @ViewChild('configControls') configControls: ConfigControlsComponent

    constructor(
        protected configurationService: ConfigurationService,
        private http: HttpClient,
    ) {
        super(configurationService)
    }

    buildControls(): void {
        this.addSection('Main', [
            this.addControl('display', 'Display', ControlTypes.boolean),
            this.addControl('shadedContainer', 'Shaded Container', ControlTypes.boolean),
            this.addControl('disableRounding', 'Disable Rounding', ControlTypes.boolean),
            this.addControl('title', 'Title', ControlTypes.string, {
                fullWidth: true,
            }),
            this.addControl('subtitle', 'Subtitle', ControlTypes.string, {
                fullWidth: true,
            }),
        ])

        this.configControls?.refresh()
    }
}
