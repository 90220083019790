import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core'
import { Subject } from 'rxjs'
import { ConfigurationService } from 'src/app/services/configuration.service'
import set from 'lodash-es/set'

interface IMenuItem {
    type?: 'a' | 'a-ng' | 'a-group'
    content?: string
    routerLink?: string
    href?: string
    children?: Array<IMenuItem>
    toolId?: string
}

@Component({
    selector: 'tool-menu-builder',
    templateUrl: './menu-builder.html',
    styleUrls: ['./menu-builder.scss', '../control.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class MenuBuilderToolComponent implements OnDestroy, OnInit {
    private $destroy = new Subject<void>()

    @Input() configPath: string
    @Input() key: string
    @Input() navItems: Array<IMenuItem>
    @Input() options: any

    menuItemTypes = [{
        value: 'a',
        display: 'External Link',
    }, {
        value: 'a-ng',
        display: 'Internal Link',
    }, {
        value: 'a-group',
        display: 'Group',
    }]

    get fullConfigPath(): string {
        return `${this.configPath}.${this.key}`
    }

    constructor(
        private configurationService: ConfigurationService,
    ) {}

    ngOnInit(): void {
        this.navItems = this.tagItems(this.navItems)
    }

    ngOnDestroy(): void {
        this.$destroy?.next()
        this.$destroy?.complete()
    }

    tagItems(items: Array<IMenuItem>, parentId?: string): Array<IMenuItem> {
        return items.map((item, index) => {
            const parentTag = !!parentId ? `${parentId}-` : ''
            const toolId = `${parentTag}${index}`
            const newItem = {
                ...item,
                toolId,
            }
            if (!!item.children) {
                newItem.children = this.tagItems(item.children, toolId)
            }
            return newItem
        })
    }

    processAdd(toItems: Array<IMenuItem>): void {
        toItems.push({
            type: 'a',
            content: 'New Nav Item',
            href: ''
        })
        this.navItems = this.tagItems(this.navItems)
        this.saveNavItems()
    }

    processRemove(toolId: string): void {
        this.navItems = this.removeItemAtToolId(this.navItems, toolId)
        this.saveNavItems()
    }

    processMoveUp(toolId: string): void {
        const item = this.getItemAtToolId(this.navItems, toolId)
        this.moveItem(this.navItems, item, 'up')
        this.saveNavItems()
    }

    processMoveDown(toolId: string): void {
        const item = this.getItemAtToolId(this.navItems, toolId)
        this.moveItem(this.navItems, item, 'down')
        this.saveNavItems()
    }

    processTypeChange(event: any): void {
        const type = event.target.value
        const toolId = event.target.getAttribute('attr-toolId')
        const item = this.getItemAtToolId(this.navItems, toolId)
        item.type = type
        switch (type) {
            case 'a':
                item.href = item.href ?? ''
                break
            case 'a-ng':
                item.routerLink = item.routerLink ?? ''
                break
            case 'a-group':
                item.children = item.children ?? []
                break
        }
        this.saveNavItems()
    }

    processDataChange(event: any, dataKey: string): void {
        const data = event.target.value
        const toolId = event.target.getAttribute('attr-toolId')
        const item = this.getItemAtToolId(this.navItems, toolId)
        item[dataKey] = data
        this.saveNavItems()
    }

    getItemAtToolId(items: Array<IMenuItem>, toolId: string): IMenuItem {
        let found = false
        let targetItem = null
        items.forEach((item) => {
            if (found) {
                return
            }
            if (item.toolId === toolId) {
                found = true
                targetItem = item
            } else {
                if (!!item.children) {
                    const childItem = this.getItemAtToolId(item.children, toolId)
                    if (!!childItem) {
                        found = true
                        targetItem = childItem
                    }
                }
            }
        })
        return targetItem
    }

    removeItemAtToolId(items: Array<IMenuItem>, toolId: string): Array<IMenuItem> {
        items = items.filter(item => {
            if (!!item.children) {
                item.children = this.removeItemAtToolId(item.children, toolId)
            }
            return item.toolId !== toolId
        })
        return items
    }

    moveItem(items: Array<IMenuItem>, itemToMove: IMenuItem, direction: 'up' | 'down'): void {
        const itemIndex = items.indexOf(itemToMove)
        if (itemIndex === 0 && direction === 'up') {
            // found it first in the list, don't move it up
            return
        }
        if (itemIndex === items.length - 1 && direction === 'down') {
            // found it last in the list, don't move it down
            return
        }
        if (itemIndex > -1) {
            // found it in the list, move it
            items.splice(itemIndex, 1)
            if (direction === 'up') {
                items.splice(itemIndex - 1, 0, itemToMove)
            } else {
                items.splice(itemIndex + 1, 0, itemToMove)
            }
        } else {
            // didn't find it in this list, check the children
            items.forEach(item => {
                if (!!item.children) {
                    this.moveItem(item.children, itemToMove, direction)
                }
            })
        }
    }

    saveNavItems(): void {
        set(this.configurationService.configuration, this.fullConfigPath, this.navItems)
        this.configurationService.$configuration.next(this.configurationService.configuration)
    }
}
