import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, HostListener, Inject, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild, ViewEncapsulation } from '@angular/core'
import { ViewportScroller, DOCUMENT } from '@angular/common'
import { Subject, Subscription } from 'rxjs'
import { debounceTime, takeUntil } from 'rxjs/operators'
import get from 'lodash-es/get'
import isEmpty from 'lodash-es/isEmpty'

// Services
import { SearchService } from './../../../services/search.service'
import { UtilityService } from 'src/app/services/utility.service'
import { ConfigurationService } from 'src/app/services/configuration.service'

// Pipes
import { NumberPipe } from './../../../pipes/number.pipe'

// Interfaces
import { IBaseComponent } from 'src/app/interfaces/components/component-base.interface'
import { IListDetailsConfiguration } from 'src/app/interfaces/components/component-list-detail.interface'
import { IComponentDevOptions } from 'src/app/modules/dev/dev.page'

export const ListDetailsComponentDefaults: IListDetailsConfiguration = {
    type: 'detail_list',
    display: true,
    devMode: false,
    fixedOnScroll: true,
    fields: [
        {
            fields: [
                {
                    label: 'Manufacturer',
                    value: '{{make}}'
                },
                {
                    label: 'Model',
                    value: '{{model}}'
                },
                {
                    label: 'Year',
                    value: '{{year}}'
                },
                {
                    label: 'Subcategory',
                    value: '{{sub_category}}'
                },
                {
                    label: 'Equipment #',
                    value: '{{display_equipment_number}}'
                },
                {
                    label: 'Serial #',
                    value: '{{serial_number}}'
                },
                {
                    label: 'Meter',
                    value: '{{meter_hours|number}} hours'
                },
                {
                    label: 'Meter',
                    value: '{{meter_miles|number}} miles'
                },
                {
                    class: 'is-text-capitalized',
                    label: 'Location',
                    value: '{{branch_city|lowercase}}, {{branch_state}}'
                }
            ],
            key: 'details',
            label: 'Machine Details'
        },
        {
            key: 'detailed_description',
            label: 'Additional Information',
            value: '{{detailed_description}}'
        },
        {
            config: {
                icon: 'copy'
            },
            key: 'downloads',
            label: 'Downloads',
            type: 'download_flyer',
            value: 'Equipment Flyer'
        },
        {
            condition: 'is_financing_eligible',
            configuration: 'catalog.financing',
            label: 'Financing'
        },
        {
            configuration: 'catalog.terms',
            label: 'Terms'
        },
        {
            condition: 'is_warranty_eligible',
            configuration: 'catalog.warranty',
            label: 'Warranty'
        }
    ],
}

export const ListDetailsComponentDevOpts: IComponentDevOptions = {
    note: 'Copy/paste an asset ID from any equipment details page to load data here.  E.g. "A43108709" found in the url https://xyz-catalog.develop.rouseservices.com/equipment/detail/A43108709',
    config: {
        ...ListDetailsComponentDefaults,
        devMode: true,
        dataGroup: 'detail-equipment',
        assetId: '',
        fixedOnScroll: false,
    },
}

@Component({
    selector: 'ras-list-details',
    styleUrls: ['./list-details.component.scss'],
    templateUrl: './list-details.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class ListDetailsComponent implements OnInit, OnDestroy, OnChanges, IBaseComponent {
    private $destroy = new Subject<void>()
    private $scroll = new Subject<void>()

    public isLoading: boolean
    public containers = []
    public containerActive
    public isFixed = false

    @Input() configuration: IListDetailsConfiguration
    @Input() data: any
    @ViewChild('listDetailsScrollable') listDetailsScrollable: ElementRef
    @HostListener( 'window:scroll' )
    onScroll() {
        if (this.configuration.fixedOnScroll) {
            this.$scroll.next()
        }
    }

    constructor(private searchService: SearchService,
                private ref: ChangeDetectorRef,
                private configurationService: ConfigurationService,
                private elementRef: ElementRef,
                private numberPipe: NumberPipe,
                private viewportScroller: ViewportScroller,
                @Inject( DOCUMENT ) private document: Document ) { }

    ngOnInit(): void {
        UtilityService.populateConfigDefaults(this.configuration, ListDetailsComponentDefaults)

        this.loadData()

        this.$scroll
            .pipe(
                takeUntil( this.$destroy ),
                debounceTime( 50 )
            ).subscribe( () => this.onScrollChange() )
    }

    ngOnDestroy(): void {
        this.$destroy.next()
        this.$destroy.complete()
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (
            changes.configuration?.currentValue?.dataGroup !== changes.configuration?.previousValue?.dataGroup
            || changes.configuration?.currentValue?.assetId !== changes.configuration?.previousValue?.assetId
        ) {
            this.loadData()
        }
    }

    loadData(): void {
        this.isLoading = true
        if (this.configuration.dataGroup) {
            if (this.configuration.devMode) {
                if (isEmpty(this.configuration.dataGroup) || isEmpty(this.configuration.assetId)) {
                    return
                }
                this.searchService.setEndpointParam(this.configuration.dataGroup, {
                    key: 'assetId',
                    value: this.configuration.assetId,
                })
            }
            this.searchService.requestData(this.configuration.dataGroup)
                .pipe(takeUntil(this.$destroy))
                .subscribe(data => {
                    if ( data ) {
                        this.isLoading = false
                        this.containerActive = 0
                        this.data = {...data}
                        this.data.branch_phone = this.data.branch_phone ? this.data.branch_phone : this.configurationService.getConfig('catalog.phone')
                        this.processData()
                        this.ref.markForCheck()
                    }
                })
        }
    }

    onScrollChange(): void {
        if ( this.elementRef.nativeElement.getBoundingClientRect().top <= 20 ) {
            if( !this.isFixed ) {
                this.isFixed = true
                this.ref.detectChanges()
            }
        } else {
            if ( this.isFixed ) {
                this.isFixed = false
                this.ref.detectChanges()
            }
        }

    }

    onContainerNavigate(index: number, id: string) {
        this.containerActive = index
        const scrollTo = ( index === 0 ) ? 0 : this.listDetailsScrollable.nativeElement.scrollLeft + 100

        if( this.listDetailsScrollable.nativeElement.scrollTo ) {
            this.listDetailsScrollable.nativeElement.scrollTo(
                { left: scrollTo, behavior: 'smooth' }
            )
        } else {
            this.listDetailsScrollable.nativeElement.scrollLeft = scrollTo
        }

        // Vertical scrolling to the element
        const element = this.document.getElementById( id )
        const top = element.offsetTop - 140
        this.viewportScroller.scrollToPosition([0, top])
    }

    getDataContainer(path: string): { data?: any, config?: any } {
        const key = path.split('.')
        const data = (key.length > 1) ? this.data : this.data[key[0]]
        const config = get(this.configuration.fields, path, null)
        return data ? { data, config } : {}
    }

    getTitleContainer(containerKey: string) {
        const containerSplitted = containerKey.split('.')
        if (containerSplitted.length > 1) {
            return this.configuration.fields[containerSplitted[0]][containerSplitted[1]].label
        } else {
            return this.configuration.fields[containerKey].label
        }
    }

    private processData() {
        const fieldSets = this.configuration.fields.map( itemFields => {

            if (itemFields.type === 'modal-link' || itemFields.type === 'downloads') {
                return itemFields
            }

            if( itemFields.configuration ) {
                if( itemFields.condition && this.data[ itemFields.condition ] || !itemFields.condition) {
                    return {
                        ...itemFields,
                        key: itemFields.key ? itemFields.key : itemFields.configuration,
                        value: this.configurationService.getConfig( itemFields.configuration )
                    }
                }
                return  null
            }

            if( itemFields.fields ) {
                const displayFields = itemFields.fields
                    .map( singleField => ( { ...singleField, value: UtilityService.replaceTemplate( this.getFieldValue( singleField.value ), this.data) } ) )
                    .filter( singleField =>  !!singleField.value )
                if (itemFields.include_configurations && 'configurations' in this.data) {
                    this.data.configurations.forEach((config) => {
                        Object.entries(config).forEach(([label, val]: [string, string]) => {
                            displayFields.push({
                                label,
                                value: val,
                            })
                        })
                    })
                }
                return {
                    ...itemFields,
                    fields: displayFields
                }
            }

            const value = UtilityService.replaceTemplate( itemFields.value, this.data )
            if( value ) {
                return {
                    ...itemFields,
                    value: UtilityService.replaceTemplate( itemFields.value, this.data )
                }
            }
        }).filter(itemFields => !!itemFields)

        // RAS-3734
        const fields = fieldSets.find(element => 'fields' in element)
        if (!!fields) {
            const meterHourIndex = fields.fields.findIndex(item => item.label.toLowerCase() === 'meter' && item.value.includes('hours'))
            const meterMileIndex = fields.fields.findIndex(item => item.label.toLowerCase() === 'meter' && item.value.includes('miles'))

            if (meterHourIndex >= 0 && meterMileIndex >= 0) {
                fields.fields.splice(meterMileIndex, 1)
            }
        }

        this.containers = fieldSets
    }

    private getFieldValue( value: string ) {
        if( value.includes('|') ) {
            const result = value.match(/([a-zA-Z0-9_]*)(\|)([a-z]*)/)

            if( !this.data[ result[1] ] ) {
                return ''
            }

            switch( result[3] ) {
                case 'number':
                    return value.split(/[({{)(.*)(}})]+/).map( item => item === result[0] ? this.numberPipe.transform( ~~this.data[ result[1] ] ) : item ).join('')
                case 'lowercase':
                    return value.replace(result[0], this.data[ result[1] ].toLowerCase())
                default:
                    return value
            }
        }
        return value
    }
}
