import { DOCUMENT, ViewportScroller } from '@angular/common'
import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, HostBinding, Inject, Input, OnInit, ViewChild } from '@angular/core'
import { SwiperContainer } from 'swiper/element'

// Interfaces
import { IBaseComponent } from 'src/app/interfaces/components/component-base.interface'
import { IHeroCarouseConfiguration, IHeroCarouselSlides } from 'src/app/interfaces/components/component-hero-carousel.interface'
import { IComponentDevOptions } from 'src/app/modules/dev/dev.page'
import { UtilityService } from 'src/app/services/utility.service'
import { QueryService } from 'src/app/services/query.service'

export const HeroCarouselComponentDefaults: IHeroCarouseConfiguration = {
    type: 'hero_carousel',
    display: true,
    options: {
        autoplay: {
            delay: 3000
        },
        lazy: true,
        loop: true,
        pagination: {
            clickable: true,
            el: '.pagination'
        },
        slidesPerView: 1
    },
    slides: [],
}

export const HeroCarouselComponentDevOpts: IComponentDevOptions = {
    config: {
        ...HeroCarouselComponentDefaults,
        slides: [
            {
                image_src: 'https://catalog-assets.rousesales.com/xyz/hero-carousel/banner-1.jpeg',
                image_alt: 'XYZ Equipments',
                headline_content: 'Quality Used Equipment',
                subtext_content: 'Browse all of our used equipment',
                button_content: 'View Inventory',
                button_link: {
                    type: 'a-ng',
                    link: '/equipment/search'
                }
            },
            {
                image_src: 'https://catalog-assets.rousesales.com/xyz/hero-carousel/banner-2.jpeg',
                image_alt: 'XYZ Equipments',
                headline_content: 'Great Deals Available',
                subtext_content: 'Browse our inventory of well-maintained machines',
                button_content: 'See our Featured machines',
                button_link: {
                    type: 'a-ng',
                    link: '/equipment/search',
                    params: {
                        'equipment.is_featured': true
                    }
                }
            },
        ]
    },
}

@Component({
    selector: 'ras-hero-carousel',
    styleUrls: ['./hero-carousel.component.scss'],
    templateUrl: './hero-carousel.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class HeroCarouselComponent implements IBaseComponent, OnInit, AfterViewInit {
    private readonly isIE: boolean = UtilityService.isIE()

    @Input() configuration: IHeroCarouseConfiguration
    @HostBinding('class') get cssClass() { return this.isIE ? 'is-ie': '' }
    @ViewChild('slider', { static: false }) slider: ElementRef<SwiperContainer>

    public slides: Array<IHeroCarouselSlides>

    public sliderConfiguration: any = {}
    public index = 0
    public mainConfig

    constructor(
        private ref: ChangeDetectorRef,
        private viewport: ViewportScroller,
        private queryService: QueryService,
        @Inject(DOCUMENT) private document,
    ) {}

    ngOnInit(): void {
        UtilityService.populateConfigDefaults(this.configuration, HeroCarouselComponentDefaults)
        this.slides = this.configuration.slides
        this.sliderConfiguration = this.getConfiguration()
    }

    ngAfterViewInit(): void {
        Object.assign(this.slider.nativeElement, this.sliderConfiguration)
        this.slider.nativeElement.initialize()
        this.ref.markForCheck()
    }

    getParams(params: Record<string, string>) {
        return this.queryService.getCustomParams(params)
    }

    onPageJump(link: string) {
        if (!link) {
            return
        }

        const element = this.document.querySelector(link)

        if(element) {
            this.viewport.scrollToPosition([0, element.offsetTop - 30 ])
        }
    }

    private getConfiguration() {
        return {
            ...this.configuration.options,
            spaceBetween: 10,
            autoHeight: false,
            loop: this.slides.length === 1 ? false : true,
            navigation: {
                nextEl: '.swiper-button-next-hero',
                prevEl: '.swiper-button-prev-hero',
            },
        }
    }
}
