import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
    name: 'hasProp',
    standalone: false
})
export class HasPropPipe implements PipeTransform {
    transform(object: object, prop: string): boolean {
        return object.hasOwnProperty(prop)
    }
}
