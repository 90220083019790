{
  "name": "rouse-sales-catalog",
  "version": "2.22.0",
  "scripts": {
    "build": "npm run build:app",
    "build:app": "ng build --output-hashing=all",
    "build:docs": "swagger-combine docs/api.yaml -o docs/api_public.yaml",
    "build:storybook": "npm run docs:json && build-storybook",
    "update:runtime": "node update-runtime.js",
    "lint": "ng lint",
    "ng": "ng",
    "test": "ng test",
    "start": "ng serve --proxy-config proxy.conf.json",
    "docs:json": "compodoc -p ./tsconfig.json -e json -d .",
    "docs:api": " node docs/api.js",
    "storybook": "npm run docs:json && start-storybook -p 6006",
    "build-storybook": "npm run docs:json && build-storybook",
    "e2e-chrome-desktop": "wdio ./e2e/configs/chrome-desktop.conf.js",
    "e2e-chrome-mobile": "wdio ./e2e/configs/chrome-mobile.conf.js",
    "analyze:bundle": "ng build --stats-json &git& webpack-bundle-analyzer dist/rouse-catalog/stats.json"
  },
  "private": true,
  "dependencies": {
    "@angular-devkit/architect": "^0.1901.7",
    "@angular-slider/ngx-slider": "^19.0.0",
    "@angular/animations": "^19.1.6",
    "@angular/cdk": "^19.1.4",
    "@angular/common": "^19.1.6",
    "@angular/compiler": "^19.1.6",
    "@angular/core": "^19.1.6",
    "@angular/fire": "^19.0.0",
    "@angular/forms": "^19.1.6",
    "@angular/material": "^19.1.4",
    "@angular/platform-browser": "^19.1.6",
    "@angular/platform-browser-dynamic": "^19.1.6",
    "@angular/router": "^19.1.6",
    "@auth0/angular-jwt": "^5.0.2",
    "@auth0/auth0-angular": "^2.2.3",
    "@fortawesome/angular-fontawesome": "^0.10.2",
    "@fortawesome/fontawesome-svg-core": "^1.2.35",
    "@fortawesome/free-brands-svg-icons": "^5.9.0",
    "@fortawesome/free-regular-svg-icons": "^5.15.3",
    "@fortawesome/free-solid-svg-icons": "^5.15.3",
    "@ngx-loading-bar/core": "^6.0.2",
    "@ngx-loading-bar/http-client": "^6.0.2",
    "@ngx-loading-bar/router": "^6.0.2",
    "@sentry/angular": "^9.1.0",
    "@sentry/tracing": "^7.3.0",
    "@types/css-font-loading-module": "^0.0.7",
    "core-js": "^3.6.5",
    "css-loader": "^6.7.3",
    "dompurify": "^3.2.4",
    "dotenv": "^8.2.0",
    "firebase": "^11.4.0",
    "fs": "0.0.1-security",
    "lodash-es": "^4.17.21",
    "process": "^0.11.10",
    "retry-axios": "^2.6.0",
    "rxjs": "^7.8.1",
    "style-loader": "^3.3.2",
    "tslib": "^2.0.0",
    "util": "^0.12.5",
    "webdriverio": "^8.39.1",
    "zone.js": "^0.15.0"
  },
  "devDependencies": {
    "@angular-eslint/builder": "^18.1.0",
    "@angular-eslint/eslint-plugin": "^18.1.0",
    "@angular-eslint/eslint-plugin-template": "^18.1.0",
    "@angular-eslint/template-parser": "^18.1.0",
    "@angular/build": "^19.2.0",
    "@angular/cli": "^19.1.7",
    "@angular/compiler-cli": "^19.1.6",
    "@angular/language-service": "^19.1.6",
    "@babel/core": "^7.21.4",
    "@babel/preset-env": "^7.21.4",
    "@babel/register": "^7.18.6",
    "@compodoc/compodoc": "^1.1.25",
    "@dlenroc/testrail": "1.9.1",
    "@google-cloud/secret-manager": "^5.6.0",
    "@stylistic/eslint-plugin": "^2.3.0",
    "@stylistic/eslint-plugin-ts": "^2.3.0",
    "@types/dompurify": "^3.0.2",
    "@types/jasmine": "~3.6.0",
    "@types/jasminewd2": "~2.0.3",
    "@types/lodash-es": "^4.17.6",
    "@types/node": "^12.11.1",
    "@typescript-eslint/eslint-plugin": "^7.16.1",
    "@typescript-eslint/parser": "^7.16.1",
    "@wdio/cli": "^8.39.1",
    "@wdio/junit-reporter": "^8.39.0",
    "@wdio/local-runner": "^8.39.1",
    "@wdio/mocha-framework": "^8.39.0",
    "@wdio/shared-store-service": "^8.39.1",
    "@wdio/spec-reporter": "^8.39.0",
    "acorn": "^8.1.0",
    "angular-http-server": "^1.9.0",
    "axios": "^1.8.3",
    "babel-loader": "^9.1.2",
    "chai": "^4.2.0",
    "es5-ext": "^0.10.64",
    "eslint": "^8.33.0",
    "eslint-config-airbnb-base": "^15.0.0",
    "eslint-config-prettier": "^7.2.0",
    "eslint-plugin-import": "^2.22.1",
    "eslint-plugin-jsdoc": "latest",
    "eslint-plugin-prefer-arrow": "latest",
    "eslint-plugin-prettier": "^3.3.1",
    "eslint-plugin-webdriverio": "^1.0.1",
    "form-data": "^3.0.0",
    "glob": "^7.1.6",
    "husky": "^4.3.6",
    "jasmine-core": "~3.8.0",
    "jasmine-marbles": "^0.9.0",
    "jasmine-spec-reporter": "~5.0.0",
    "karma": "^6.4.2",
    "karma-chrome-launcher": "~3.1.0",
    "karma-coverage-istanbul-reporter": "~3.0.2",
    "karma-jasmine": "~4.0.0",
    "karma-jasmine-html-reporter": "^1.5.0",
    "moment": "^2.29.1",
    "moment-timezone": "^0.5.32",
    "prettier": "^2.2.1",
    "sass": "^1.62.1",
    "sass-loader": "^13.2.2",
    "swiper": "^11.1.4",
    "ts-node": "^10.9.1",
    "typescript": "5.7.3",
    "validator": "^13.5.2",
    "wdio-intercept-service": "^4.1.2",
    "wdio-safaridriver-service": "^1.0.0",
    "webpack": "^5.94.0",
    "webpack-bundle-analyzer": "^4.9.1",
    "yamljs": "^0.3.0",
    "yargs": "^15.3.1"
  }
}
