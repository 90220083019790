import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, HostBinding, HostListener, Input, OnDestroy, OnInit, SkipSelf } from '@angular/core'
import { Subject } from 'rxjs'
import { IComponentDevOptions } from 'src/app/modules/dev/dev.page'

// Services
import { UtilityService } from 'src/app/services/utility.service'
import { FormService } from 'src/app/services/form.service'
import { ThemeService } from 'src/app/services/theme.service'

// Interfaces
import { IFooterCustom1Configuration } from './footer-custom-1.interface'
import { FormControl, FormGroup, Validators } from '@angular/forms'

export const FooterCustom1ComponentDefaults: IFooterCustom1Configuration = {
    type: 'footer_custom_1',
    display: true,
    breakpointMedium: 600,
    breakpointLarge: 800,
    logo: {
        src: '',
        alt: '',
        href: '',
    },
    navGroups: [],
    addressGroups: [],
    phone: {
        href: '',
        content: ''
    },
    subNavItems: [],
    copyright: '',
    poweredByLogo: '',
    poweredByLink: '',
    styles: {},
    childConfigs: {},
}

export const FooterCustom1ComponentDevOpts: IComponentDevOptions = {
    config: {
        ...FooterCustom1ComponentDefaults,
    },
}

@Component({
    selector: 'ras-footer-custom-1',
    templateUrl: './footer-custom-1.component.html',
    styleUrls: ['./footer-custom-1.component.scss'],
    standalone: false
})
export class FooterCustom1Component implements OnInit, OnDestroy, AfterViewInit {
    private $destroy = new Subject<void>()

    @HostBinding('class') hostClass: string

    @Input() configuration: IFooterCustom1Configuration

    mode: 'small' | 'medium' | 'large'
    form = new FormGroup({
        email: new FormControl('', [
            Validators.required,
            Validators.email
        ])
    })
    formError: string = ''
    formSuccess: string = ''

    constructor(
        private elRef: ElementRef,
        @SkipSelf() private ref: ChangeDetectorRef, // SkipSelf allows hostClass change to be detected on first load
        private themeService: ThemeService,
        private formService: FormService,
    ) {}

    @HostListener('window:resize', [])
    onResize() {
        this.setBreakpoints()
    }

    ngOnInit(): void {
        UtilityService.populateConfigDefaults(this.configuration, FooterCustom1ComponentDefaults)
        this.themeService.setStylesOnElement(this.configuration.styles, this.elRef)
        this.ref.markForCheck()
    }

    ngAfterViewInit(): void {
        this.setBreakpoints()
        this.ref.detectChanges()
    }

    ngOnDestroy(): void {
        this.$destroy?.next()
        this.$destroy?.complete()
    }

    setBreakpoints(): void {
        const width = document.body.clientWidth
        if (width >= this.configuration.breakpointLarge) {
            if (this.hostClass !== 'breakpoint-large') {
                this.setMode('large')
            }
            return
        }
        if (width >= this.configuration.breakpointMedium) {
            if (this.hostClass !== 'breakpoint-medium') {
                this.setMode('medium')
            }
            return
        }
        if (this.hostClass !== 'breakpoint-small') {
            this.setMode('small')
        }
    }

    getCopyright(): string {
        const date = new Date()
        const year = date.getFullYear()
        return this.configuration.copyright ? this.configuration.copyright.replace('{{year}}', year.toString()) : ''
    }

    setMode(mode: 'small' | 'medium' | 'large'): void {
        this.mode = mode
        this.hostClass = `breakpoint-${mode}`
        this.ref.markForCheck()
    }
}
