import { ChangeDetectionStrategy, ChangeDetectorRef, Component, DoCheck, Input, OnInit } from '@angular/core'
import { DomSanitizer, SafeHtml } from '@angular/platform-browser'
import DOMPurify from 'dompurify'

// Services
import { UtilityService } from 'src/app/services/utility.service'

// Interfaces
import { IBannerComponentConfiguration } from 'src/app/interfaces/components/component-banner.interface'
import { IBaseComponent } from 'src/app/interfaces/components/component-base.interface'
import { IComponentDevOptions } from 'src/app/modules/dev/dev.page'

export const BannerComponentDefaults: IBannerComponentConfiguration = {
    type: 'banner',
    display: true,
    content: '',
    class: '',
}

export const BannerComponentDevOpts: IComponentDevOptions = {
    config: {
        ...BannerComponentDefaults,
        content: '<p>Sample Banner Content</p>',
        class: 'warning',
    }
}

@Component({
    selector: 'ras-banner',
    styles: [`
        div { text-align: center; display: block; overflow: hidden; box-sizing: }
        div.warning { background-color: #D65444; color: #FFF; }
        div.default { background-color:#f2f2f2 }
    `],
    template: `<div *ngIf="configuration.display" [className]="configuration.class ? configuration.class : ''" [innerHTML]="configuration.content"></div>`,
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class BannerComponent implements IBaseComponent, OnInit, DoCheck {
    @Input() configuration: IBannerComponentConfiguration

    public content: SafeHtml

    constructor(
        private sanitizer: DomSanitizer,
        private ref: ChangeDetectorRef,
    ) {}

    ngOnInit(): void {
        UtilityService.populateConfigDefaults(this.configuration, BannerComponentDefaults)
        this.setContent(this.configuration.content)
    }

    ngDoCheck(): void {
        this.setContent(this.configuration.content)
        this.ref.markForCheck()
    }

    setContent(content: any) {
        this.content = this.sanitize(content)
        this.ref.markForCheck()
    }

    sanitize(content: any) {
        return this.sanitizer.bypassSecurityTrustHtml(
            DOMPurify.sanitize(content)
        )
    }
}
