import { Pipe, PipeTransform } from '@angular/core'

import { UtilityService } from 'src/app/services/utility.service'

@Pipe({
    name: 'rasTemplate',
    standalone: false
})
export class RasTemplatePipe implements PipeTransform {

    /**
     * Retrieves a tranformed template string that replaces the keys and applies the required pipes
     * @example "The meter value is {{meter_value|rounded:2}}%" will return "The meter value is 5.32%"
     * @param template contains the template string to be formatted
     * @param data main object to replace the values with the keys
     */
    transform( template: any, data?: any ): any {
        if(!data) {
            return template
        }

        if(!template) {
            return null
        }

        return template
            .split(/[{{(*$)}}]+/).map( key => {
                const [ value, rawPipe ] = key.split('|')
                let keyValue = value
                keyValue =  data.hasOwnProperty( keyValue ) ? data[keyValue] : keyValue

                // In case there is a pipe applied to the key_value_pair, we proceed to apply it
                if( rawPipe ) {
                    const [ pipeName, ...pipeParams ] = rawPipe.split(':')
                    keyValue = this.applyPipe( keyValue, pipeName, pipeParams ? pipeParams : [] )
                }

                return keyValue
            })
            .join('')
    }

    private applyPipe( value: any, pipeName: string, params: Array<any> ) {
        switch( pipeName ) {
            case 'round':
                return parseFloat( value ).toFixed( params[0] ? params[0] : 2 )
            case 'roundToPercentage':
                return Math.round( parseFloat( value ) * 1e2)
            default:
                return value
        }
    }

}
