import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core'
import { takeUntil } from 'rxjs/operators'
import { Subject } from 'rxjs'

// Services
import { QueryService } from 'src/app/services/query.service'

// Interfaces
import { IFilterListConfiguration } from 'src/app/interfaces/filters/filter-list.interface'
import { IBaseComponent } from 'src/app/interfaces/components/component-base.interface'

@Component({
    selector: 'ras-filter-list',
    styleUrls: ['./filter-list.component.scss'],
    templateUrl: './filter-list.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class FilterListComponent implements OnInit, OnDestroy, IBaseComponent {
    private $destroy =  new Subject<void>()
    private filterType: 'list' | 'individual' = 'individual'

    @Input() configuration: IFilterListConfiguration

    public items: Array<{ name: string, selected: boolean, key: string }> = []
    public isFullView: boolean = false
    public instantApply: boolean

    constructor( private queryService: QueryService, private ref: ChangeDetectorRef ) {}

    ngOnInit(): void {
        // If it is an array of key, we treat them diferently
        this.filterType = !!this.configuration.keys ? 'list' : 'individual'
        this.instantApply = !!this.configuration.instantApply

        // Add the filter params to the state that request filters data to the backend
        switch(this.filterType) {
            case 'individual':
                this.queryService.addParamToFetch( this.configuration.dataGroup, this.configuration.key )
                break
            case 'list':
                this.queryService.addParamToFetch( this.configuration.dataGroup, this.configuration.keys )
                break
        }

        this.queryService.paramsData
            .pipe(
                takeUntil( this.$destroy )
            )
            .subscribe( data => {
                this.formatData( data )
            })
    }

    ngOnDestroy(): void {
        this.$destroy.next()
        this.$destroy.complete()
    }

    onChange() {
        const itemsSelected = this.items.filter( item => item.selected).map( item => item )

        // All items are already selected, do not proceed with any logic
        if( this.items.length === itemsSelected.length ) {
            return
        }

        if ( !itemsSelected.length ) {
            this.queryService.removeParamKey( this.configuration.dataGroup, this.configuration.key )
        } else {

            // Add the selected value with the key in the configuration
            if ( this.filterType === 'individual' ) {
                this.queryService.add(
                    this.configuration.dataGroup,
                    this.configuration.key,
                    itemsSelected.map( item => item.name )
                )
            }

            // Add the selected value, with each item as the key (Quick filters)
            if ( this.filterType === 'list') {
                // First Remove all Params
                this.items.forEach( item => {
                    this.queryService.removeParamKey( this.configuration.dataGroup, item.key)
                })
                // Add only the selected ones
                itemsSelected.forEach( item => {
                    this.queryService.add(
                        this.configuration.dataGroup,
                        item.key,
                        'true'
                    )
                })
            }

        }
    }
    /**
     * If the name label is set on the configuration, we use that one
     */
    getName( key: string ) {
        return this.queryService.getParamLabel(key)
    }

    private formatData( data: any ) {
        // The filter endpoint has data for the specific filter list and it is individual
        if ( data && data[this.configuration.key] && this.filterType === 'individual' ) {
            this.items = data[this.configuration.key].map( item => ({
                    name: item,
                    selected: this.queryService.hasParam( this.configuration.dataGroup, this.configuration.key, item ),
                    key: this.configuration.key,
                    label: this.getName( this.configuration.key )
                }))

            if( this.configuration.applySort ) {
                this.items = this.items.sort( (a,b) => b.name.localeCompare(a.name) )
            }

            this.ref.markForCheck()
        }
        // The filter endpoint has data, and it is a list of filters [each has it's own key Ex: Quick Filter]
        if ( data && this.filterType === 'list' ) {
            const items = []
            this.configuration.keys.forEach( key => {
                if (data[key]) {
                    items.push({
                        name: this.getName(key),
                        selected: this.queryService.hasParam( this.configuration.dataGroup, key ),
                        key,
                        label: this.getName(key)
                    })
                }

            })
            this.items = items
            this.ref.markForCheck()
        }
    }
}
