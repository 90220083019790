import { Directive, EventEmitter, ElementRef, HostListener, Output } from '@angular/core'

@Directive({
    selector: '[rasClickOutside]',
    standalone: false
})

export class ClickOutsideDirective {

    @Output('rasClickOutside') clickOutside = new EventEmitter<MouseEvent>()

    constructor(
        private elementRef: ElementRef,
    ) {
        /* This will catch the proxied click event from iFrames */
        (window as any).addEventListener('message', msg => {
            msg = msg.data
            switch (msg.type) {
                case 'click':
                    this.clickOutside.emit(msg)
                    break
            }
        })
    }

    @HostListener('document:click', [ '$event' ])
    public onDocumentClick(event: MouseEvent): void {
        const targetElement = event.target as HTMLElement
        if (!!targetElement && !this.elementRef.nativeElement.contains(targetElement) && !targetElement.closest('.mat-mdc-select-panel')) {
            this.clickOutside.emit(event)
        }
    }
}
