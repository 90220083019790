import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core'
import { DomSanitizer, SafeHtml } from '@angular/platform-browser'
import DOMPurify from 'dompurify'

@Component({
    selector: '[ras-inject-content]',
    template: `
        <div *ngIf="safeHtml" [innerHTML]="safeHtml"></div>
        `,
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class InjectContentComponent implements OnChanges {
    public safeHtml: SafeHtml

    @Input() styles: string
    @Input() html: string

    constructor( private sanitizer: DomSanitizer ) {}

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.styles || changes.html) {
            this.safeHtml = this.sanitizer.bypassSecurityTrustHtml(
                changes.styles
                    ? `<style>${ DOMPurify.sanitize( this.styles ) } </style>`
                    : DOMPurify.sanitize( this.html )
                )
        }
    }

    setHtml(html: string) {
        this.html = html
        this.safeHtml = this.sanitizer.bypassSecurityTrustHtml(
            DOMPurify.sanitize(this.html)
        )
    }
}
